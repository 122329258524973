@media screen and (min-width: 2900px) {

  /* Gift Card Listing start */
  .gift-detail-bg {
    transform: translatey(-28%);
  }

  .gift-detail-waves {
    transform: translateY(-18%);
  }

  /* Gift Card Listing end */

  /* contact-us-page-start */
  .contact-yellow-leaf {
    top: 18vw;
  }

  .contact-pink-leaf {
    top: 0;
  }

  /* contact-us-page-start */
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 1200px) {
  .header-nav > ul > li a i{pointer-events: none}
}

@media screen and (min-width: 992px) {
  .book-event-sticky-box {
    transition: all 0.4s ease-in-out;
  }

  .book-event-sticky-box.is-sticked {
    position: fixed;
    top: 20px;
  }

  .book-event-sticky-box.is-sticked.with-top-space {
    top: 90px;
  }

  .book-event-sticky-box.is-normal {
    position: absolute;
    bottom: 100px;
  }
}

@media screen and (max-width: 1610px) {

  /* contact-us-page-start */
  .contact-yellow-leaf {
    top: 38vw;
    width: 5vw;
  }

  .contact-pink-leaf {
    top: 21vw;
    width: 5vw;
  }

  .contact-waves {
    top: 2vw;
  }

  /* contact-us-page-start */
}

@media screen and (max-width: 1660px) {
  .common-sec {
    padding: 80px 0;
  }

  /* home-page-start*/
  .hero-sec.common-sec {
    padding-top: 150px;
  }

  .hero-img {
    margin-right: -100px;
    margin-left: 50px;
  }

  .hero-con .sec-head .sec-title {
    font-size: 90px;
  }

  .paint-n-sip {
    font-size: 45px;
    padding: 23px 80px;
    margin-right: 40px;
  }

  .explore-art-sec .sec-head .sec-title {
    font-size: 80px;
  }

  .explore-art-box-title {
    font-size: 18px;
  }

  .experiences-pink-leaf {
    top: -4vw;
    width: 14vw;
  }

  .arts-save-live-brush {
    top: 19vw;
  }

  .about-social-art-bruch {
    width: 250px;
  }

  .arts-save-live-brush {
    width: 250px;
  }

  /* home-page-end */
  /* check-out-page */
  .checkout-sec .experiences-pink-leaf {
    top: -5vw;
  }

  .checkout-sec .experiences-yellow-leaf {
    bottom: -8vw;
  }

  /* event-details */

  .event-detail-slide img {
    height: 294px;
    object-position: top;
  }
}

@media screen and (max-width: 1460px) {

  /* home-page-start */
  /* .header .container {
    width: 98%;
  } */
  .hero-video-sec .sec-head .sec-title {
    font-size: 60px;
  }

  .hero-sec.common-sec .container {
    width: 85%;
  }

  .hero-img {
    margin-right: -60px;
    margin-left: 0;
  }

  .experiences-pink-leaf {
    top: -2vw;
    width: 11vw;
  }

  .experiences-yellow-leaf {
    width: 10vw;
  }

  .gifts-shape-img {
    transform: translate(-60%, -50%);
    width: 20vw;
    height: 32vh;
  }

  .about-social-art-bruch {
    top: -18vw;
    width: 297px;
  }

  .arts-save-live-brush {
    top: 26vw;
  }

  .newsletter-img {
    position: absolute;
    z-index: -1;
    right: -107px;
    top: -228px;
  }

  .qeesland-pink-leaf {
    top: -3vw;
  }

  /* home-page-end */

  /* chechout-page-start*/
  .checkout-sec .container {
    width: 92%;
  }

  .checkout-payment .nav-tabs .nav-item a img {
    height: 20px;
  }

  .checkout-sec .experiences-pink-leaf {
    top: -4vw;
  }

  /* chechout-page-end*/

  /* contact-us-page-start*/
  .contact-social-media {
    padding-left: 0;
  }

  .contact-waves {
    top: 10vw;
  }

  /* contact-us-page-end*/

  /* venu-page-start */
  /* .popular-drink-food-sec .container,
  .offer-sec .container,
  .venue-detail-contact-sec .container,
  .team-building-page .container,
  .victoria-sec .container,
  .tasmania-sec .container {
    width: 85%;
  } */

  .qeesland-sec .container {
    max-width: 1460px;
  }

  /* venu-page-end*/

  /* event-detail-page-start */

  .listing-event-sec {
    padding-top: 60px;
  }

  .event-detail-sec .container,
  .event-about-sec .container {
    padding: 0 40px;
  }

  /* event-detail-page-end */

  /* thankyou-page */

  .thank-you-sec.common-sec {
    padding-top: 100px;
  }

  /* private-event */
  .private-events-pink-leaf {
    top: -5vw;
  }

  .private-events-yellow-leaf {
    width: 7vw;
  }

  .header-search {
    padding-right: 10px;
  }

  .header-search .input-group {
    max-width: 215px;
  }

  .event-from-row {
    flex-direction: column;
    gap: 0;
  }
  .event-from-row .form-group.event-from.event-from-person {
    max-width: 100%;
  }

  .search-open {
    min-width: 300px;
  }
}

@media screen and (max-width: 1348px) {

  /* home-page-start*/
  .video-waves {
    top: -40vw;
  }

  .gifts-img {
    margin-left: 110px;
  }

  .arts-save-live-brush {
    width: 240px;
    top: 31vw;
  }

  .about-social-art-bruch {
    top: -18vw;
    width: 207px;
  }

  /* home-page-end*/



}

@media screen and (max-width: 1299px) {

  /* home-page-start*/
  .hero-sec.common-sec .container,
  .git-card-listing-sec .container {
    width: 95%;
  }

  .newsletter-img {
    position: absolute;
    z-index: -1;
    right: -87px;
    top: -235px;
  }

  /* home-page-end*/

  /* chechout-page-start*/
  .checkout-payment .nav-tabs .nav-item a img {
    height: 18px;
  }

  /* chechout-page-end*/

  /* gift-detail-page-start*/
  .gift-detail-bg {
    top: -70px;
  }

  /* gift-detail-page-end*/

  /* contact-us-page-start*/
  .contact-email {
    margin: 0 0px;
  }

  /* .container.is-full {
    width: 95%;
  } */

  .contact-pink-leaf {
    top: 0;
  }

  .contact-yellow-leaf {
    top: 70vw;
  }

  /* contact-us-page-end*/
  /* venue-page-start*/
  /* .venue-detail-about-section .container,
  .popular-drink-food-sec .container,
  .offer-sec .container,
  .venue-detail-about-section .container,
  .venue-detail-sec .container,
  .venue-detail-contact-sec .container,
  .team-building-page .container,
  .qeesland-sec .container,
  .victoria-sec .container,
  .tasmania-sec .container {
    width: 95%;
  } */

  /* popular-drink-food-sec */

  .popular-drink-food-sec .popular-item-row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .popular-item-row .popular-item-col {
    width: 20%;
  }

  .popular-item-col .popular-item-box {
    margin-bottom: 25px;
  }

  .benifit-team-build-item .team-build-item-desc {
    margin-bottom: 30px;
  }

  .benifit-team-brush {
    top: -20px;
    right: -88px;
    width: 300px;
    height: 458px;
  }

  .tasmania-yellow-leaf {
    top: -128px;
  }

  .popular-drink-food-sec::after {
    width: 110px;
  }

  /* venue-page-end*/

  /* venue-detail-page */

  /* event-detail */
  .private-events-pink-leaf {
    width: 8vw;
    top: -2vw;
  }

  .event-detail-sec .container,
  .event-about-sec .container {
    padding: 0 30px;
  }

  /* category-details-sec */
  .category-details-sec-waves {
    top: 10%;
  }
}

@media (min-width: 1281px) and (max-width: 1730px) {
  .popular-drink-food-sec::after {
    bottom: 0%;
    width: 118px;
  }
}

@media screen and (max-width: 1199px) {
  .common-sec {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sec-head .sec-sub-title {
    font-size: 24px;
  }

  .sec-head .sec-title {
    font-size: 48px;
  }

  .sec-title.title-md {
    font-size: 34px;
  }

  .hero-video-sec {
    height: 50vh;
    padding-top: 70px;
  }

  .hero-video-sec .sec-head .sec-title {
    font-size: 50px;
  }

  /* mobile-menu */
  .header-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 350px;
    background-color: #3f328a;
    padding: 70px 20px 40px;
    transform: translateX(400px);
    transition: transform 0.4s ease-in-out;
    z-index: 9999;
  }

  body.nav-open .header-nav {
    transform: translateX(0);
  }

  .header-nav .header-nav-toggle-btn {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  body.nav-open {
    overflow: hidden;
  }

  .header-nav ul {
    text-align: start;
  }

  .header-nav>ul>li {
    margin-left: 0;
    margin-right: 0;
  }

  .header-nav ul li a {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .header-nav>ul>li>ul li a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }

  .header-nav ul li a i {
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 15px;
    width: 25px;
    height: 25px;
    z-index: 999;
    transform: none;
    bottom: inherit;
    border: none;
  }

  .header-nav>ul>li>ul {
    position: unset;
    background-color: transparent;
    color: #fff;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    padding: 0;
    display: none;
    border-radius: 0;
  }

  .header-nav>ul>li>ul.active {
    display: block;
  }

  .header-back-drop,
  .filter-back-drop,
  .event-share-back-drop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: block;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transition: opacity 0.3s ease-in-out;
  }

  body.nav-open .header-back-drop,
  body.filter-open .filter-back-drop,
  body.share-open .event-share-back-drop {
    opacity: 0.7;
    visibility: visible;
  }

  body.nav-open,
  body.filter-open,
  body.share-open {
    overflow: hidden;
  }

  /* mobile-menu */
  /* home-page-start*/
  .hero-sec.common-sec {
    padding-bottom: 0;
    background-size: 100%;
  }

  .hero-con .sec-head .sec-title {
    font-size: 70px;
    margin-top: -5px;
  }

  .hero-con .cms-con {
    max-width: unset;
  }

  .paint-n-sip {
    font-size: 35px;
    padding: 14px 40px;
    margin-top: -5px;
    margin-right: -10px;
  }

  .experiences-sec::after {
    width: 37vw;
    height: 35vh;
    top: -9vw;
  }

  .explore-art-sec .sec-head .sec-title {
    font-size: 70px;
  }

  .explore-art-sec .sec-head {
    margin-bottom: 30px;
  }

  .explore-art-boxes {
    position: relative;
    min-height: 25vh;
  }

  .show .explore-art-box:nth-child(2),
  .show .explore-art-box:nth-child(3) {
    top: 15%;
  }

  .show .explore-art-box:nth-child(4),
  .show .explore-art-box:nth-child(5) {
    top: 30%;
  }

  .explore-art-box-title {
    font-size: 16px;
  }

  .experiences-pink-leaf {
    top: -12vw;
    width: 17vw;
  }

  .experiences-yellow-leaf {
    width: 14vw;
    bottom: -8vw;
  }

  .venues-box-img {
    height: 180px;
  }

  .venues-swiper-btn.is-next {
    right: 10px;
  }

  .venues-swiper-btn.is-prev {
    left: 0;
  }

  .venues-box-title {
    margin-top: 10px;
  }

  .popular-drink-food-sec::after {
    width: 130px;
    bottom: 62px;
  }

  .gifts-shape-img {
    top: 70%;
  }

  .gifts-img {
    margin-left: 120px;
  }

  .as-soon-on-wrap .sec-head {
    padding: 30px 45px 30px 0;
  }

  .video-inner {
    height: 460px;
  }

  .testimonial-sec.common-sec::after {
    height: 36vh;
    width: 36vh;
    bottom: 5vh;
  }

  .testimonial-sec .sec-head::before {
    width: 100px;
    height: 80px;
    top: -30px;
    left: 26%;
  }

  .customer-name-box {
    margin-top: 23px;
  }

  .customer-name-box .customer-name-logo {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .testimonial-sec .venues-swiper-btn-wrap {
    display: none;
  }

  .about-social-art-counter {
    margin-bottom: 40px;
  }

  .arts-save-live-brush {
    top: 33vw;
    width: 18vw;
  }

  .about-social-art-bruch {
    top: 43vw;
    width: 19vw;
  }

  .about-social-counter-box {
    padding: 0 20px;
  }

  .arts-save-live-con {
    max-width: 530px;
  }

  .faq-accordion {
    max-width: 100%;
  }

  .faq-sec button.accordion-button {
    padding: 30px 0;
  }

  .newsletter-img {
    right: 0;
    top: -154px;
  }

  .newsletter-img img {
    max-width: 200px;
  }

  .newsletter-sec {
    padding-top: 110px;
  }

  /* home-page-end*/
  /* footer */
  .footer-item {
    padding-left: 10px;
  }

  /* footer */

  /* listing-page-star*/
  .loading-img {
    margin-top: 55px;
    margin-bottom: 20px;
  }

  /* listing-page-end*/

  /* event-page-start*/
  .popular-events-sec .event-item-box {
    margin-bottom: 30px;
    display: block;
    /* width: 300px; */
  }

  .listing-event-item-cate>.row {
    overflow-x: auto;
  }

  .private-events-yellow-leaf {
    bottom: -5vw;
  }

  .react-daterange-picker--closed .react-daterange-picker__wrapper {
    transform: scale(0.8);
    transform-origin: left;
  }

  .event-detail-art-bruch-right {
    top: -7vw;
    width: 15vw;
  }

  .event-detail-art-bruch-left {
    width: 15vw;
    display: none;
  }

  .listing-event-venue .listing-event-list {
    max-height: 200px;
  }


  .listing-item-box-wrapper {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* event-page-end*/

  /* gift-card-page-start*/
  .gift-cards-col {
    padding-left: 15px;
  }

  .gift-card-purchase .btn {
    padding: 12px 20px;
  }

  .gift-card-recipient {
    padding: 20px;
  }

  .recipient-details,
  .gift-best-wishes {
    padding: 0 20px;
  }

  /* .gift-detail-page-end */

  /* checkout-page-start*/
  .checkout-payment {
    margin-top: 30px;
  }

  .remove-cart {
    margin-top: 20px;
  }

  .gift-detail-bg {
    top: -30px;
  }

  .checkout-payment {
    padding-left: 0;
  }

  .checkout-sec .experiences-pink-leaf {
    top: -5vw;
  }

  .checkout-sec .experiences-yellow-leaf {
    bottom: 70vw;
  }

  .checkout-mobile-sticky-payment {
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    width: 100%;
    display: flex;
    background-color: #2f2457e0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .price-total-wrap {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price-total-wrap .price-title {
    margin-left: 3px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .card-form .btn-lg {
    margin-top: 0;
    border-radius: 0;
  }

  /* checkout-page-end*/

  /* contact-us-page-start */
  .contact-img img {
    width: 100%;
    margin-top: 30px;
  }

  .contact-form {
    margin-top: 40px;
    margin-left: 0;
    padding: 40px;
  }

  .contact-pink-leaf {
    width: 10vw;
    top: -5vw;
  }

  .contact-yellow-leaf {
    top: 33vw;
    width: 10vw;
  }

  .contact-waves {
    transform: rotate(0);
  }

  /* contact-us-page-end */

  /* venue-listing-page-start*/
  .qeesland-sec.common-sec,
  .victoria-sec.common-sec {
    padding-bottom: 40px;
  }

  .tasmania-sec.common-sec {
    padding-bottom: 0;
  }

  .venue-detail-about-section .about-con .cms-con p {
    margin-bottom: 30px;
  }

  .venue-curve-img {
    bottom: 50%;
    transform: translateY(50%);
  }

  /* venue-listing-page-endt*/

  /*team-buliding-page-start */

  /* why-team-build-sec */

  .why-team-bulid-item-box {
    margin-bottom: 30px;
  }

  .benifit-team-build-sec.common-sec {
    padding-top: 50px;
  }

  .benifit-team-build-sec::after {
    top: -222px;
  }

  .team-build-brush {
    top: -110px;
    width: 300px;
  }

  .benifit-team-brush {
    top: -70px;
  }

  .why-team-build-sec .cms-con {
    max-width: 96%;
  }

  /*team-buliding-page-end */

  /* thankyou-page */
  .thank-you-sec.common-sec {
    padding-top: 70px;
  }

  /* notfound */
  .notfound-yellow-leaf {
    bottom: 0;
  }

  .whats-included-con {
    padding-left: 30px;
  }

  .whats-included-con .cms-con li {
    font-size: 17px;
    background-size: 20px;
    padding-left: 32px;
  }

  .whats-included-img img {
    width: 626px;
    height: 575px;
  }

  .sec-head.text-2 .sec-title {
    font-size: 28px;
  }

  .private-event {
    padding: 30px;
  }

  /* category-details-sec */

  .category-details-sec .sec-head .sec-title {
    margin-bottom: 20px;
  }

  .gift-card-sec::before {
    width: 37vw;
    height: 40vh;
    top: 10vw;
    right: -100px;
  }

  /* footer */

  .social-media a {
    height: 30px;
    width: 30px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .checkout-sec .experiences-pink-leaf {
    width: 8vw;
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0 15px;
  }

  .common-sec {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sec-head .sec-sub-title {
    font-size: 28px;
  }

  .sec-head .sec-title {
    font-size: 36px;
  }

  .sec-title.title-md {
    font-size: 32px;
  }

  .banner-event-booking-sec .btn {
    font-size: 12px;
    line-height: 1;
    padding: 14px 18px;
  }

  /* slider */

  .slick-dots li button {
    width: 20px;
  }

  /* home-page-start*/
  .hero-sec.common-sec .container,
  .git-card-listing-sec .container {
    width: 100%;
  }

  .hero-sec.common-sec {
    padding-top: 130px;
  }

  .hero-con {
    text-align: center;
  }

  .hero-con .sec-head {
    display: inline-block;
  }

  .hero-con .cms-con {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-img {
    margin-right: 0;
    margin-left: 0;
    margin-top: 50px;
  }

  .explore-art-sec .sec-head .sec-title {
    font-size: 54px;
  }

  .explore-art-boxes {
    min-height: unset;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .explore-art-box {
    position: unset;
    margin-bottom: 20px;
  }

  .explore-art-box,
  .show .explore-art-box:nth-child(1),
  .show .explore-art-box:nth-child(2),
  .show .explore-art-box:nth-child(3),
  .show .explore-art-box:nth-child(4),
  .show .explore-art-box:nth-child(5) {
    transform: unset;
    left: unset;
    top: unset;
    width: unset;
    padding-left: 10px;
    padding-right: 10px;
  }

  .explore-art-box:nth-child(1),
  .explore-art-box:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .explore-art-box:nth-child(3),
  .explore-art-box:nth-child(4),
  .explore-art-box:nth-child(5) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .explore-art-box-title {
    opacity: 1;
  }

  .experiences-tabs .tab-content>.tab-pane {
    padding: 0 0 30px;
  }

  .experiences-tabs-con {
    padding-left: 0;
  }

  .experiences-tabs-con .sec-head .sec-title {
    font-size: 28px;
  }

  .experiences-tabs .nav-link {
    font-size: 15px;
  }

  .experiences-tabs .nav {
    padding: 30px 20px;
  }

  .experiences-tabs .nav-item {
    margin-right: 20px;
  }

  .venues-box-title {
    font-size: 15px;
  }

  .as-soon-on-wrap .sec-head {
    padding: 30px 15px 30px 0;
  }

  .as-soon-on-wrap .as-soon-on-title {
    font-size: 20px;
  }

  .as-soon-on-swiper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .as-soon-on-wrap {
    padding-left: 25px;
  }

  .video-inner {
    height: 342px;
  }

  .video-icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  .testimonial-sec .sec-head {
    margin-bottom: 32px;
  }

  .testimonial-sec .sec-head::before {
    left: 18%;
  }

  .testimonial-sec .slick-dots {
    bottom: -35px;
  }

  .about-social-art-bruch {
    top: 63vw;
    width: 23vw;
  }

  .about-social-art-img {
    padding-left: 0;
  }

  .about-social-art-img img {
    width: 80%;
    height: 80%;
    object-fit: cover;
  }

  .about-social-art-sec .cms-con span {
    margin-bottom: 20px;
  }

  .about-social-counter-num span {
    font-size: 34px;
  }

  .about-social-art-counter {
    margin-bottom: 30px;
  }

  .about-social-art-con {
    margin-top: 20px;
  }

  .about-social-art-sec .cms-con span {
    font-size: 20px;
  }

  .arts-save-live-con {
    padding-left: 0;
    padding-top: 40px;
    max-width: 100%;
  }

  .arts-save-live-brush {
    top: 68vw;
    width: 23vw;
  }

  .gifts-img {
    margin-left: 220px;
  }

  .gifts-shape-img {
    transform: translate(-67%, -50%);
    width: 40vw;
    height: 42vh;
    top: 55%;
  }

  .gifts-con {
    padding-top: 30px;
  }

  .newsletter-sec .form-group {
    width: 100%;
  }

  .newsletter-box {
    padding: 35px;
  }

  .newsletter-sec {
    padding-top: 110px;
  }

  .newsletter-box .sec-head {
    margin-bottom: 15px;
  }

  .newsletter-box .sec-head .sec-title {
    font-size: 30px;
  }

  .faq-accordion .accordion-body p {
    max-width: 650px;
  }

  .newsletter-img {
    right: 0;
    top: -132px;
  }

  /* home-page-end */
  /* footer */
  .footer-sec {
    padding-top: 0;
  }

  .footer-sec.with-bottom-spacing {
    padding-bottom: 63px;
  }

  .footer-contact-col {
    margin-left: 0;
  }

  .footer-logo a img {
    width: 300px;
  }

  .footer-box.logo-section {
    margin-bottom: 30px;
  }

  .footer-item {
    padding-left: 0;
  }

  .footer-contact-col {
    margin-top: 30px;
  }

  .footer-menu .footer-con ul li {
    margin-top: 20px;
  }

  /* footer */

  /*event-listing-page-start */
  .listing-event-item-wrap {
    padding-left: 0;
  }

  .event-item-title {
    font-size: 16px;
  }



  .gift-card-details {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .gift-best-wishes {
    margin-bottom: 30px;
  }

  .recipient-details p {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .listing-item-box-wrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .listing-search-filter-wrap .form-control{
    width: 220px;
  }
  /*event-listing-page-end */

  /* gift-detail-page-start*/
  .gift-detail-bg {
    top: -30px;
  }

  /* gift-detail-page-start*/

  /* contact-us-page-start*/
  .contact-form .btn {
    margin-top: 30px;
  }

  .contact-img {
    margin-top: 30px;
  }

  .container.is-full {
    width: 100%;
  }

  .contact-yellow-leaf {
    top: 34vw;
  }

  .contact-form {
    padding: 30px;
  }

  /* contact-us-page-end*/

  /* event--detail-page-start*/
  .book-event {
    margin-top: 20px;
  }

  .event-detail-included ul li {
    width: 100%;
  }

  .recent-events {
    margin-top: 30px;
  }

  .pottery-con .sec-head .sec-title {
    font-size: 30px;
  }

  .event-item-box {
    margin-bottom: 25px;
    display: block;
  }

  .event-detail-art-bruch-right {
    display: none;
  }

  .event-detail-art-bruch-left {
    bottom: -7vw;
  }

  .private-events-pink-leaf {
    top: -3vw;
  }

  .react-daterange-picker--closed .react-daterange-picker__wrapper {
    transform: unset;
    transform-origin: unset;
  }

  .event-detail-sec .container,
  .event-about-sec .container {
    padding: 0px 15px;
  }

  .listing-event-filter-details {
    margin-top: 10px;
  }



  /* event-detail-page-end*/

  /* checkout-page-start*/
  .checkout-sec .sec-head {
    margin-bottom: 30px;
  }

  .checkout-payment .nav-tabs .nav-item a img {
    height: 17px;
  }

  .remove-cart {
    margin-top: 0;
  }

  .checkout-sec .container {
    width: 100%;
  }

  .checkout-sec .experiences-yellow-leaf {
    bottom: 80vw;
  }

  /* checkout-page-end*/

  /* venue-listing-page-start*/

  .qeesland-sec.common-sec,
  .victoria-sec.common-sec {
    padding-bottom: 20px;
  }

  .tasmania-sec.common-sec {
    padding-bottom: 60px;
  }

  .qeesland-con .sec-head .sec-title,
  .victoria-con .sec-head .sec-title,
  .tasmania-sec .sec-head .sec-title {
    font-size: 30px;
    line-height: 25px;
  }

  .venues-listing-item-box {
    margin-bottom: 25px;
  }

  .tasmania-yellow-leaf {
    top: -185px;
    right: -20px;
    width: 142px;
    height: 198px;
  }

  .qeesland-pink-leaf {
    top: -3vw;
    width: 7vw;
  }

  .qeesland-yellow-leaf {
    bottom: 0;
  }

  /* venue-listing-page-end*/

  /* venue-detail-page-start */

  .venue-detail-about-section .container,
  .popular-drink-food-sec .container,
  .offer-sec .container,
  .venue-detail-about-section .container,
  .venue-detail-sec .container,
  .venue-detail-contact-sec .container,
  .team-building-page .container,
  .qeesland-sec .container,
  .victoria-sec .container,
  .tasmania-sec .container {
    width: 100%;
  }

  /* venue-detail-page-end */

  /* venuu-detail-page-start*/
  .venue-detail-banner-con .sec-head .sec-title {
    font-size: 30px;
  }

  .venue-detail-banner img {
    margin-top: 20px;
  }

  .venue-detail-about-section::before {
    top: 10%;
  }

  .venue-location {
    margin-top: 30px;
  }

  /* offer-sec */

  .offer-sec {
    padding-bottom: 40px;
  }

  .offer-sec .offer-code {
    margin-bottom: 20px;
  }

  /* popular-drink-food-sec */

  .popular-drink-food-sec {
    padding-bottom: 30px;
  }

  .popular-drink-food-sec::after {
    right: -7px;
    width: 90px;
    height: 188px;
  }

  .popular-drink-food-sec .popular-item-row {
    margin-right: -20px;
    margin-left: -20px;
  }

  .popular-item-row .popular-item-col {
    width: 25%;
  }

  .popular-item-col .popular-item-box {
    margin-right: 10px;
    margin-left: 10px;
  }

  .team-build-brush {
    top: -47px;
    width: 195px;
    left: -20px;
  }

  /* venue-detail-contact-sec */

  .venue-detail-contact-sec .contact-detail-item {
    margin-left: 0;
  }

  /* venuu-detail-page-end*/

  /*team-buliding-page-start */

  /* why-team-build-sec */

  .why-team-build-img-con {
    padding-top: 140px;
  }

  /* .benifit-team-build-sec */
  .benifit-team-build-sec.common-sec {
    padding-top: 30px;
  }

  .benifit-team-build-sec::after {
    top: 40%;
    height: 350px;
  }

  .benifit-team-build-sec .sec-head .sec-title {
    font-size: 30px;
    line-height: 38px;
  }

  .benifit-team-build-sec .sec-head {
    margin-bottom: 30px;
  }

  .why-team-build-sec .cms-con {
    max-width: 100%;
  }

  .benifit-team-build-con {
    max-width: inherit;
  }

  .benifit-team-build-item .team-build-item-desc {
    margin-bottom: 25px;
    margin-left: 15px;
  }

  .benifit-team-build-img {
    margin: 0 auto;
    margin-top: 20px;
  }

  .benifit-team-brush {
    top: 37%;
  }

  /*  team-buliding-page-end */
  .booking-success {
    padding: 20px 30px;
  }

  /* thankyou-page */

  .thank-you-sec.common-sec {
    padding-top: 60px;
  }

  .thank-you-sec .thankyou-pink-leaf {
    top: 0;
  }

  .event-detail-slide img {
    height: 364px;
  }

  .whats-included-img img {
    width: 426px;
    height: 495px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .whats-included-art-bruch-left {
    bottom: -30px;
  }

  .category-details-con .cms-con h5 {
    font-size: 20px;
  }

  .category-details-sec .sec-head .sec-title {
    margin-bottom: 15px;
  }

  .scroll-to-top span {
    margin-top: 4px;
  }

  .popular-events-sec {
    padding-top: 60px;
  }

  .popular-events-link {
    display: none;
  }


}

@media screen and (max-width: 767px) {
  .common-sec {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .header-nav-toggle-btn {
    margin-left: 0;
  }

  .header .btn {
    margin-right: 17px;
    padding: 3px 15px 3px 5px;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    position: relative;
  }

  .header .btn::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ffffff29;
  }

  .hero-video-sec {
    height: auto;
    padding-top: 140px;
    padding-bottom: 60px;
  }

  .banner-event-booking-sec {
    padding: 20px 25px;
    flex-direction: column;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }

  .banner-event-booking-sec form {
    flex-direction: column;
    width: 100%;
  }

  .banner-event-booking-sec .btn {
    width: 100%;
  }

  .banner-event-booking-sec form select:nth-child(1) {
    flex: auto;
  }

  .banner-event-booking-sec form .input-group {
    flex: auto;
    padding: 14px 20px;
  }

  .hero-video-sec .sec-head .sec-title {
    font-size: 36px;
  }

  .hero-video-sec .cms-con p {
    font-size: 18px
  }

  .venues-sec .sec-head {
    margin-bottom: 20px;
  }

  .video-inner {
    height: 328px;
  }

  .testimonial-slide-box p {
    max-width: 600px;
  }

  .experiences-tabs .nav {
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
    padding: 20px;
    justify-content: flex-start !important;
  }

  .experiences-tabs .nav-item {
    margin-right: 20px;
  }

  .experiences-tabs-img {
    margin-bottom: 20px;
  }

  .experiences-tabs-con {
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .experiences-tabs-con .cms-con p {
    max-width: 100%;
  }

  .experiences-tabs-con .cms-con {
    margin-bottom: 25px;
  }

  .experiences-yellow-leaf {
    width: 24vw;
    bottom: -13vw;
  }

  .experiences-pink-leaf {
    top: -19vw;
    width: 23vw;
  }

  .gifts-shape-img {
    transform: translate(-67%, -50%);
    width: 40vw;
    height: 92vh;
    top: 55%;
  }

  .newsletter-sec {
    padding-top: 110px;
  }

  .newsletter-sec .form-group .form-control {
    padding: 17px;
  }

  .newsletter-sec .btn {
    padding: 18px;
  }

  /* home-page-end */
  /* footer */
  .footer-box.logo-section {
    margin-bottom: 0;
  }

  /* footer */
  .listing-event-sec.common-sec {
    padding-top: 0;
  }

  .listing-event-search {
    row-gap: 10px;
  }

  .listing-search-box {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }

  .listing-event-count {
    order: 2;
    flex-grow: 1 !important;
    padding-left: 0 !important;
  }

  .listing-filter-side-cat {
    margin-left: 0;
    width: 100%;
    text-align: center;
    border: none !important;
    color: #fff !important;
  }

  .listing-m-filter-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #3f328a;
    z-index: 2;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .listing-search-filter-wrap {
    margin-left: -15px;
    margin-right: -15px;
  }


  .listing-search-filter-wrap .form-control {
    padding-left: 15px;
    padding-right: 50px;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 50%);
    border-radius: 0;
  }

  .listing-event-filter-details {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .listing-filter-inner {
    overflow: auto;
    max-height: calc(100svh - 200px);
  }


  .listing-event-sortlist {
    flex: 1;
    margin-left: 0;
    flex: 0 0 50%;
    max-width: 50%;
    border-right: 1px solid rgb(255 255 255 / 50%);
    text-align: center;
  }

  .listing-event-clear {
    order: 3;
  }

  .gift-card-purchase .btn {
    width: 100px;
  }

  /* event-detail-page-start */
  .event-detail-included ul li {
    width: 100%;
  }

  .event-box {
    margin: 20px 0;
  }

  .pottery-con .sec-head .sec-title {
    font-size: 25px;
  }

  .event-detail-art-bruch-right {
    width: 17vw;
    top: -15vw;
  }

  /* event-detail-page-end */

  /* checkout-page-start */
  .checkout-payment .nav-tabs .nav-item a img {
    height: 15px;
  }

  .checkout-sec a.btn {
    margin-top: 5px;
  }

  .card-form .btn-lg {
    padding: 14px;
  }

  .price-total-wrap {
    padding: 10px 15px;
    display: unset;
  }

  .price-total-wrap>span {
    font-size: 14px;
  }

  .price-total-wrap .price-title {
    margin-left: 0;
  }

  /* checkout-page-end*/

  /* contact-us-page-start*/
  .contact-info {
    margin-bottom: 20px;
  }

  .contact-form {
    padding: 40px 20px;
  }

  .contact-img {
    margin-top: 30px;
  }

  /* contact-us-page-end*/

  /* venue-page-start*/
  .popular-item-row .popular-item-col {
    width: 33.3%;
  }

  .popular-drink-food-sec .popular-item-row {
    margin-right: -10px;
    margin-left: -10px;
  }

  .benifit-team-brush {
    top: 40%;
    height: 300px;
    width: 250px;
    right: -112px;
  }

  .venue-detail-sec .book-now-btn .btn {
    margin-top: 20px;
  }

  /* venue-page-end*/
  .btn-lg {
    padding: 18px;
  }

  .booking-ticket {
    margin-bottom: 20px;
  }

  .booking-ticket-date {
    font-size: 80px;
  }

  .booking-ticket-month {
    font-size: 18px;
  }

  .booking-ticket-time {
    margin-top: 5px;
    font-size: 14px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .booking-ticket-id {
    font-size: 20px;
  }

  .category-details-con .cms-con h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 670px) {

  /* venue-page-start*/
  .venues-box-img {
    height: 242px;
  }

  /* venue-page-end*/

  /* .gift-detail-page-start*/
  .gift-detail-bg {
    top: 0;
  }

  /* .gift-detail-page-end*/
}

@media screen and (max-width: 568px) {
  .wrapper {
    padding-top: 90px;
  }

  .sec-head .sec-title {
    font-size: 30px;
  }

  .sec-head .sec-sub-title {
    font-size: 22px;
  }

  .sec-title.title-md {
    font-size: 24px;
  }

  p,
  .cms-con li,
  .testimonial-slide-box p {
    font-size: 14px;
  }

  .hero-video-sec .container {
    padding: 0 20px;
  }

  .cms-con {
    margin-bottom: 30px;
  }

  .banner-event-booking-sec {
    padding: 25px;
  }

  .btn {
    font-size: 14px;
    padding: 12px 22px;
  }

  .btn-lg {
    font-size: 17px;
    padding: 16px;
  }

  /* slider */
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li button {
    width: 14px;
  }

  /* header */
  .header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-logo img {
    max-height: 50px;
  }

  .header.is-sticky .header-logo img {
    max-height: 40px;
  }

  .header-nav {
    width: 300px;
  }

  .header-nav ul li a {
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .header-nav ul li a i {
    top: 17px;
  }

  .header-nav>ul>li>ul li a {
    font-size: 15px;
  }

  /* header */
  /* home-page-start*/
  .hero-sec {
    margin-top: -89px;
  }

  .hero-con .sec-head .sec-title {
    font-size: 60px;
    margin-top: 0;
  }

  .paint-n-sip {
    font-size: 30px;
    padding: 12px 30px;
  }

  .hero-con .cms-con {
    max-width: unset;
  }

  .explore-art-sec .sec-head .sec-title {
    font-size: 32px;
  }

  .explore-art-boxes {
    justify-content: center;
  }

  .explore-art-box:nth-child(1),
  .explore-art-box:nth-child(2),
  .explore-art-box:nth-child(3),
  .explore-art-box:nth-child(4),
  .explore-art-box:nth-child(5),
  .explore-art-box:nth-child(6) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .explore-art-img {
    margin-bottom: 10px;
  }

  .explore-art-box-title,
  .venues-listing-item-desc .venues-listing-item-title {
    font-size: 14px;
  }

  .experiences-tabs .tab-content>.tab-pane {
    padding: 15px;
  }

  .experiences-tabs-con {
    max-width: unset;
  }

  .experiences-tabs-m-item {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: #0000004f;
  }

  .experiences-tabs-m-item img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
  }

  .experiences-tabs-m-item+.experiences-tabs-m-item {
    margin-top: 15px;
  }

  .experiences-tabs-m-item a {
    position: relative;
    padding: 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    z-index: 2;
  }

  .venues-box-img {
    height: 272px;
  }

  .as-soon-on-wrap .sec-head {
    padding: 0;
  }

  .as-seen-on .slick-dots {
    bottom: -10px;
  }

  .as-soon-on-wrap {
    border: none;
  }

  .as-soon-on-wrap .sec-head {
    border: none;
  }

  .as-soon-on-wrap {
    padding-left: 0;
  }

  .as-soon-on-wrap .as-soon-on-title {
    text-align: center;
    margin-bottom: 15px;
  }

  .about-social-counter-box {
    padding: 0 10px;
  }

  /* .as-soon-on-img img {
    max-height: 50px;
  } */

  .video-inner {
    height: 300px;
  }

  .video-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  @keyframes scrolling {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-1400px);
    }
  }

  .testimonial-sec.common-sec {
    padding-bottom: 70px;
  }

  .testimonial-slide-box p {
    max-width: 100%;
    min-height: 144px;
  }

  .testimonial-sec .sec-head .sec-title {
    font-size: 28px;
  }

  .testimonial-sec .sec-head::before {
    width: 60px;
    height: 40px;
    top: -10px;
    left: 4%;
  }

  .testimonial-sec .sec-head {
    margin-bottom: 22px;
  }

  .customer-name-box {
    margin-top: 20px;
  }

  .customer-name-box .customer-name-logo {
    margin-right: 10px;
  }

  .about-social-art-sec .cms-con span {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .about-social-art-img img {
    width: 100%;
  }

  .about-social-counter-num span {
    font-size: 20px;
  }

  .arts-save-live-con .sec-head .sec-title {
    font-size: 28px;
  }

  .about-social-art-bruch {
    top: -80px;
    width: 33vw;
  }

  .arts-save-live-con {
    padding-top: 70px;
  }

  .arts-save-live-brush {
    top: 71vw;
    width: 29vw;
  }

  .gifts-shape-img {
    width: 40vw;
    height: 34vh;
  }

  .gifts-img {
    margin-left: 110px;
  }

  .gifts-con .sec-head .sec-title {
    font-size: 28px;
  }

  .faq-accordion .accordion-header {
    padding: 0;
  }

  .faq-accordion .accordion-body {
    padding: 0 0 20px;
  }

  .faq-sec button.accordion-button {
    padding: 20px 0;
  }

  .newsletter-sec {
    padding-top: 110px;
  }

  .newsletter-box {
    padding: 25px;
  }

  .newsletter-sec .btn {
    padding: 0;
    font-size: 28px;
    min-width: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .newsletter-box .sec-head {
    margin-left: 0;
  }

  .newsletter-box .sec-head .sec-sub-title {
    font-size: 14px;
  }

  .newsletter-box .sec-head .sec-title {
    font-size: 24px;
  }

  /* home-page-end*/
  /* footer */
  .footer-sec.with-bottom-spacing {
    padding-bottom: 70px;
  }

  .footer-logo a img {
    width: 180px;
  }

  .footer-payment-img {
    margin-bottom: 20px;
  }

  .copy-right {
    text-align: center;
  }

  /* footer */

  .listing-event-filter {
    width: 340px;
    padding: 20px 15px;
    height: 100svh;
  }


  .listing-event-filter .filterLogo {
    padding-top: 0;
    padding-bottom: 10px;
  }

  .listing-event-sortlist {
    margin-left: 0;
  }

  .listing-event-sortlist .form-label {
    display: none;
  }

  .city-filter-listing {
    margin-top: 15px;
  }

  .gift-total-amount .btn {
    max-width: 100%;
    margin-top: 20px;
  }

  .gift-total-amount {
    margin-top: 25px;
  }

  .gift-card-form .sec-head {
    margin-top: 10px;
  }

  .gift-best-wishes {
    line-height: 1;
  }

  .recipient-details {
    margin-top: 20px;
  }

  .gift-card-purchase .btn {
    width: 90px;
  }

  .login-popup-inr {
    padding: 25px 15px;
  }

  /* event-detail-page-start*/
  .event-detail-sec.common-sec {
    padding-bottom: 20px;
  }

  .pottery-con .sec-head .sec-title {
    font-size: 20px;
  }

  .pottery-price .pottery-price-con {
    font-size: 25px;
    text-align: start;
    margin-top: 0px;
  }

  .event-share {
    position: relative;
  }

  .event-share>span {
    padding: 5px;
    font-size: 18px;
    background: #fff;
    border-radius: 50%;
    color: #130b41;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    padding-right: 8px;
  }

  .event-share-links a {
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin: 0;
  }

  .event-share-links a i {
    margin-right: 5px;
  }

  .event-share-links-wrapper {
    min-width: 200px;
    margin: 0 auto;
    padding: 15px;
    border-radius: 20px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #130b41;
    border: 1px solid rgba(255, 255, 255, 0.22);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }

  body.share-open .event-share {
    z-index: 9999;
  }

  body.share-open .event-share-links-wrapper {
    opacity: 1;
    visibility: visible;
  }

  .event-close-popup {
    display: block;
    position: absolute;
    top: 4%;
    right: 7%;
  }

  .event-share .icon-btn span {
    display: block;
  }

  .event-share-links {
    flex-wrap: wrap;
  }

  .event-share-links a span {
    display: block;
  }

  .about-con .sec-head .sec-title,
  .verified-review .sec-head .sec-title {
    font-size: 22px;
  }

  .about-con .sec-head {
    margin-bottom: 25px;
  }

  .about-con .sec-head .sec-title::after,
  .about-con .cms-con h4::after,
  .checkout-sec .sec-head .sec-title::after {
    top: 30px;
  }

  .about-con .cms-con h5 {
    font-size: 18px;
    margin-bottom: 7px;
  }

  .about-con .cms-con span {
    font-size: 18px;
  }

  .event-detail-included {
    margin-top: 20px;
  }

  .event-detail-included .included-title {
    margin-bottom: 10px;
  }

  .event-box {
    margin-top: 0;
  }

  .event-share .icon-btn {
    display: flex;
  }

  .recent-events .sec-head {
    margin-bottom: 10px;
  }

  .event-detail-slider {
    margin-bottom: 15px;
  }

  .event-detail-slide img {
    height: 200px;
  }

  .book-event {
    padding: 15px 15px 30px;
    border-radius: 15px;
    margin-top: 0;
  }

  .cms-con ul.two li {
    width: 100%;
  }

  .event-item-title {
    min-height: auto;
  }

  .event-detail-art-bruch-left {
    bottom: -10vw;
  }

  /* .recent-events .row {
    flex-wrap: nowrap;
    overflow-x: auto;
  } */

  .recent-events .row>div {
    width: 80%;
    max-width: 80%;
  }

  .listing-item-box-wrapper {
    max-width: 50%;
    flex: 0 0 50%;
  }

  /* event-detail-page-end*/

  /* check-out-page-start*/
  .checkout-terms {
    margin-top: 20px;
  }

  .checkout-form {
    margin-top: 20px;
    max-width: 100%;
  }

  .checkout-form+.checkout-form {
    margin-top: 20px;
  }

  .checkout-sec .form-details {
    padding: 20px;
  }

  .checkout-item {
    padding-right: 0;
  }

  .checkout-sec .experiences-yellow-leaf {
    bottom: -10vw;
  }

  .checkout-sec .experiences-pink-leaf {
    top: 1vw;
  }

  .chechout-total {
    border-radius: 20px;
    padding: 15px 25px;
    margin-top: 20px;
  }

  /* check-out-page-start*/

  /* gift-detail-page-start*/
  .gift-detail-bg {
    top: 20px;
  }

  .gift-card-detail-img img,
  .gift-card-checkout-img img {
    border-radius: 15px;
  }

  /* gift-detail-page-end */

  /* contact-us-page-start*/
  .contact-form {
    padding: 30px 15px;
  }

  .contact-img {
    margin-top: 30px;
  }

  .contact-form .btn {
    margin-top: 18px;
  }

  .check-box-sec {
    width: 100%;
    margin-top: 0;
  }

  .contact-form .btn {
    font-size: 18px;
  }

  /* contact-us-page-end*/

  /* login-popup */
  .popup-close {
    top: 14px;
    right: 14px;
  }

  /* login-popup */

  /* venue-listing-page-start*/
  .qeesland-con .sec-head .sec-title,
  .victoria-con .sec-head .sec-title,
  .tasmania-sec .sec-head .sec-title {
    font-size: 20px;
    line-height: 25px;
  }

  .tasmania-sec.common-sec {
    padding-bottom: 40px;
  }

  .tasmania-yellow-leaf {
    top: -94px;
    right: -20px;
    width: 112px;
    height: 150px;
  }

  .qeesland-pink-leaf {
    top: -3vw;
    width: 9vw;
  }

  /* venue-listing-page-end*/

  /* venue-detail-banner-start */

  /* .venue-detail-banner {
    margin-left: -15px;
    margin-right: -15px;
  } */

  .venue-detail-banner img {
    height: 200px;
    margin-bottom: 10px;
    margin-top: 0;
    border-radius: 0;
  }

  .venue-detail-banner-con .sec-head .sec-title {
    font-size: 20px;
  }

  .venue-detail-about-section::before {
    top: 20%;
  }

  /* offer-sec */

  .offer-sec {
    padding-bottom: 20px;
  }

  .offer-sec .sec-head {
    margin-bottom: 25px;
  }

  .offer-sec .offer-code {
    padding: 15px;
  }

  .offer-sec .offer-code .offer-code-con h6 {
    font-size: 16px;
    line-height: 19px;
  }

  /* popular-drink-food-sec */

  .popular-drink-food-sec {
    padding-bottom: 20px;
  }

  .popular-drink-food-sec::after {
    right: -5px;
    width: 89px;
    height: 140px;
    top: -120px;
  }

  .popular-drink-food-sec .sec-head {
    margin-bottom: 25px;
  }

  .popular-drink-food-sec .popular-item-col {
    width: 50%;
    margin-bottom: 12px;
  }

  .popular-item-col .popular-item-box {
    margin-right: 0;
    margin-left: 0;
  }

  .popular-item-box .popular-item-desc .popular-item-title {
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }

  .venue-location {
    padding: 15px;
    border-radius: 15px;
  }

  .book-now-btn button {
    max-width: 100%;
  }

  /* privet-event-sec */

  .private-event {
    padding: 15px;
  }

  .private-event {
    border-radius: 15px;
  }

  .private-event .sec-head {
    margin-bottom: 30px;
  }

  .private-event .sec-head .sec-title {
    font-size: 20px;
    line-height: 25px;
  }

  .private-event .send-meaasge a {
    margin-top: 0;
    padding: 17px 22px;
  }

  /* venue-detail-banner-end */

  /*  why-team-buliding-page-start*/

  /* why-team-build-sec */

  .why-team-build-sec .sec-head .sec-title {
    margin-bottom: 18px;
  }

  .why-team-build-sec .sec-head .sec-title br {
    display: none;
  }

  .why-team-build-img-con {
    padding-top: 120px;
  }

  /* benifit-team-build-sec */
  .benifit-team-build-sec.common-sec {
    padding-top: 20px;
  }

  .benifit-team-build-sec::after {
    display: none;
  }

  .benifit-team-build-sec .sec-head {
    margin-bottom: 25px;
  }

  .benifit-team-build-sec .sec-head .sec-title br {
    display: none;
  }

  .benifit-team-build-sec .sec-head .sec-title {
    font-size: 22px;
    line-height: 27px;
  }

  .benifit-team-build-item .team-build-item-desc .team-build-item-title {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 7px;
  }

  .team-build-brush {
    top: -39px;
    width: 112px;
  }

  .benifit-team-brush {
    top: -77px;
    right: -114px;
  }

  /*  why-team-buliding-page-end*/
  .react-calendar {
    width: 314px;
  }

  .booking-success {
    padding: 10px 15px;
  }

  .booking-success>.sec-head .sec-title {
    font-size: 24px;
  }

  .booking-success-message {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .booking-con .sec-head {
    margin-bottom: 25px;
  }

  .booking-ticket {
    margin-top: 20px;
  }

  /* thankyou-page */
  .thank-you-sec.common-sec {
    padding-top: 40px;
  }

  .loader-img img {
    width: 90px;
  }

  .listing-event-sec.common-sec {
    padding-top: 0;
  }

  .category-details-con .cms-con h5 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .sec-head.text-2 .sec-title {
    font-size: 22px;
  }

  .sec-head.text-2 {
    margin-bottom: 25px;
  }

  .whats-included-con {
    padding-left: 0;
  }

  .whats-included-art-bruch-left,
  .whats-included-art-bruch-right {
    width: 25vw;
  }

  .whats-included-img img {
    height: 395px;
    margin-top: 40px;
  }

  .whats-included-con .cms-con li {
    font-size: 14px;
    background-size: 15px;
    padding-left: 24px;
    margin-bottom: 10px;
    background-position: top 4px left;
  }

  .category-details-sec .sec-head .sec-title {
    margin-bottom: 10px;
  }

  .category-details-sec-waves {
    top: 30%;
  }

  .explore-art-box.is-link-box .is-link-box-inner {
    background-color: #332775;
    border-radius: 15px;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 100%;
    display: block;
  }

  .explore-art-box.is-link-box .is-link-box-inner a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .explore-art-box.is-link-box .is-link-box-inner a .icon-arrow-right {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
  }

  .hero-img {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .hero-con {
    margin-bottom: 30px;
  }

  .hero-sec-waves {
    margin-top: -20px;
    pointer-events: none;
  }

  .hero-sec-waves img {
    transform: scale(2);
    margin: 0 auto;
  }

  .explore-art-sec .sec-head,
  .experiences-sec .sec-head {
    margin-bottom: 20px;
  }

  .event-detail-art-bruch-right {
    width: 37vw;
    top: -56vw;
  }

  .whats-included-art-bruch-left {
    display: none;
  }

  .venue-detail-contact-sec .event-detail-art-bruch-left {
    display: none;
  }

  .whats-included-sec::before {
    width: 151vw;
    height: 65vh;
    right: -72vw;
    top: -19vw;
  }

  .btn-sticky-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    border-radius: 0;
  }

  .listing-event-sec::before {
    top: -5vw;
    right: -45vw;
    width: 137vw;
    height: 80vh;
  }

  .listing-event-sec::after {
    top: 30vh;
    left: -58vw;
    width: 137vw;
    height: 90vh;
  }

  .arts-save-live-con .cms-con p:first-child {
    display: none;
  }

  .gifts-sec::after {
    top: 0px;
    left: -66vw;
    width: 70vh;
    height: 70vh;
  }

  .gifts-sec::before {
    top: -110vw;
    right: -31vw;
    width: 45vh;
    height: 45vh;
  }

  .about-con .cms-con span {
    font-weight: 400;
    font-size: 14px !important;
  }

  .about-con .cms-con blockquote {
    font-size: 14px;
    line-height: 1.3;
  }

  .about-social-art-sec::after,
  .about-social-art-sec::before {
    height: 55vh;
    width: 55vh;
  }

  .about-social-art-sec::after {
    left: -35vh;
  }

  .about-social-art-sec::before {
    right: -35vh;
    left: unset;
    top: 5vh;
  }

  .newsletter-sec::after {
    height: 30vh;
  }

  .faq-sec::before {
    height: 50vh;
    width: 50vh;
    right: -30vh;
  }

  .venue-detail-contact-sec .event-detail-art-bruch-right {
    display: none;
  }

  .event-item-box .event-item-desc {
    display: flex;
    flex-wrap: wrap;
  }

  /* 
  .event-item-box .event-item-title {
    flex: 1;
    margin-right: 15px;
  } */

  .event-item-box .event-detail-location {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .venues-sec.common-sec::after {
    height: 80vh;
  }

  .hero-sec::after {
    height: 60vh;
  }

  .explore-art-sec.common-sec::after {
    width: 45vw;
    height: 50vh;
    top: -30vh;
    right: -17vw;
  }

  .faq-sec::after {
    left: -10vw;
    width: 41vw;
    height: 65vh;
  }

  .faq-sec::after {
    top: 0;
    left: -40vw;
    width: 65vh;
    height: 65vh;
  }

  .venue-detail-sec::before {
    left: -21vw;
    height: 80vh;
  }

  .venue-detail-sec::after {
    height: 50vh;
    top: 70vw;
  }

  .thank-you-wrap::before {
    height: 60vh;
  }

  .thank-you-wrap::after {
    height: 60vh;
  }

  .remove-cart {
    margin-top: 10px;
  }

  .checkout-sec::before {
    height: 95vh;
    bottom: -40vh;
    left: -5vh;
  }

  .contact-sec::before {
    width: 45vw;
    height: 60vh;
    left: -40px;
    top: 40vw;
    bottom: 0;
  }

  .gift-card-details {
    margin-top: 0;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
  }

  .listing-event-item-cate {
    margin-top: 15px;
  }

  .header .btn {
    margin-right: 22px;
    padding-right: 20px;
  }

  .venues-box-img img {
    height: 261px;
  }

  .gift-card-sec::after {
    height: 50vh;
    width: 50vh;
    left: -42vw;
  }

  .gift-card-sec::before {
    height: 70vh;
    width: 70vh;
    right: -55vw;
  }

  .gift-card-recipient {
    border: 0;
  }

  .gift-card-recipient {
    padding: 0;
    background-color: transparent;
  }

  .gift-card-img img {
    border-radius: 0;
  }

  .testimonial-sec.common-sec::after {
    height: 56vh;
    width: 56vh;
    bottom: 20vh;
  }

  .footer-cms-links {
    padding: 20px 0 20px;
    text-align: center;
  }

  .scroll-to-top span {
    padding: 4px;
    margin-top: 5px;
  }

  .venues-listing-item-box {
    text-align: center;
  }

  .venues-listing-item-box .venues-listing-item-img,
  .event-item-img {
    border-radius: 10px;
  }

  .serch-close-button,
  .serch-button {
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 10px;
    line-height: 1;
  }

  .header-search {
    position: absolute;
    left: 0;
    right: 0;
    top: -120%;
    display: none;
    padding: 20px 5px 20px 15px;
    background: rgb(16, 8, 68);
    z-index: 2;
    display: flex;
    gap: 5px;
    transition: .4s all ease-in-out;
    -webkit-transition: .4s all ease-in-out;
    -moz-transition: .4s all ease-in-out;
    -ms-transition: .4s all ease-in-out;
    -o-transition: .4s all ease-in-out;
  }

  .serch-close-button+.search-open {
    top: 105%;
    left: 50%;
    transform: translate(-50%);
    max-width: 95%;
    width: 95%;
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
  }

  .header .search-open .btn::after {
    content: none;
  }

  .serchbar-open .header-search {
    top: 0;
  }

  .header-search .input-group {
    /* border-color: transparent; */
    flex-grow: 1;
    max-width: none;
  }

  .header-search .input-group-text {
    /* background-color: transparent;
    border-color: transparent; */
    font-size: 18px;
    /* padding: 10px;
    border-radius: 200px !important;
    -webkit-border-radius: 200px !important;
    -moz-border-radius: 200px !important;
    -ms-border-radius: 200px !important;
    -o-border-radius: 200px !important; */
  }

  .header-search .form-control {
    /* display: none; */
  }

  .popular-events-sec .listing-event-item-cate {
    margin-top: 0;
  }

  .event-tag {
    margin-bottom: 20px;
  }

  .event-tag ul {
    gap: 25px;
  }

  .event-tag ul li::after {
    right: -15px;
  }

  .hero-video-sec:before {
    background-color: rgba(19, 11, 65, 0.3);
  }

  .banner-text-wp {
    text-align: center;
  }

  .popular-events-sec .event-item-box {
    min-width: 220px;
    width: 55vw;
  }
  .categaryEventsMain{padding-top: 0;}
  .categaryEventsMain .popular-events-sec{
    padding: 30px 0;
  }
}

@media screen and (max-width: 390px) {
  /* index-page */

  .newsletter-box {
    padding: 20px;
  }

  /* venue-listing-page-start */
  .venues-box-img {
    height: 230px;
  }

  .gifts-img {
    margin-left: 90px;
  }

  .testimonial-sec .sec-head::before {
    left: 0;
  }

  .faq-accordion .accordion-header {
    padding: 0;
  }

  .faq-accordion .accordion-body {
    padding: 0 0 20px;
  }

  .faq-sec button.accordion-button {
    font-size: 18px;
    padding: 20px 0;
  }

  .newsletter-sec {
    padding-top: 130px;
  }

  .newsletter-sec .form-group {
    border: none;
  }

  .newsletter-box .sec-head {
    margin-left: 0;
  }

  .faq-accordion .accordion-body p {
    max-width: 350px;
  }

  .newsletter-img {
    position: absolute;
    z-index: -1;
    right: -12px;
    top: -125px;
  }

  .newsletter-img img {
    width: 219px;
  }

  /* venue-listing-page-end */
  /* checkout-page-strat*/
  .checkout-payment .nav-tabs .nav-item a img {
    height: 13px;
  }

  /* checkout-page-end*/

  /* venue-page-360 */
  .popular-item-box .popular-item-img {
    height: 100px;
    width: 100px;
  }

  .popular-item-col .popular-item-box {
    margin-bottom: 20px;
  }

  .hero-video-sec .sec-head .sec-title {
    font-size: 32px;
    margin-bottom: 5px;
  }

  .hero-video-sec .cms-con p {
    font-size: 14px;
    line-height: 1.2;
  }

  .hero-video-sec {
    min-height: 100vh;
  }

  /* venue-page-360 */
}

@media screen and (max-width: 350px) {

  /* header */
  .header-logo img {
    max-width: 100px;
  }

  .header .btn {
    padding: 11px 9px 9px;
    letter-spacing: unset;
  }

  .listing-event-filter {
    width: 318px;
  }

  .listing-event-filter {
    padding: 20px 10px;
  }

  /* header */
  /* venue-page-start */
  .experiences-pink-leaf {
    top: -31vw;
    width: 27vw;
  }

  .venues-box-img {
    height: 180px;
  }

  .testimonial-sec .sec-head::before {
    display: none;
  }

  .about-social-counter-box p {
    font-size: 14px;
  }

  .about-social-counter-box {
    padding-left: 10px;
  }

  .listing-search-filter-wrap .form-control {
    width: 200px;
  }

  .listing-filter-side-cat.btn {
    padding: 10px 15px;
    font-size: 12px;
    margin-left: 30px;
  }

  .gift-card-recipient {
    padding: 20px 10px;
  }

  .gift-card-img,
  .recipient-details,
  .gift-best-wishes {
    padding: 0 10px;
  }

  /* venue-page-end */
  /* login-pop-start*/
  .popup-close {
    top: 19px;
    right: 12px;
    font-size: 18px;
  }

  /* login-pop-end*/
  /* gift-card-detail-page-start */
  .gift-card-detail-img img,
  .gift-card-checkout-img img {
    border-radius: 9px;
  }

  /* gift-card-detail-page-end */
}