@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

html {
  font-size: 16px;
}

body {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  background-color: #130b41;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

a:hover {
  color: #01edc7;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Button */
.btn {
  font-weight: 700;
  font-size: 16px;
  padding: 14px 27px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 2px solid transparent;
  text-align: center;
  box-shadow: none !important;
  border-radius: 200px;
  transition: 0.4s all ease-in-out;
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.btn-outline-light:hover {
  color: #130b41;
  background-color: #fff;
}

.btn-orange-pink {
  color: #fff;
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
  background-size: 200% 200%;
  border: none;
}

.btn-orange-pink:hover {
  background-position: right;
  color: #fff;
}

.btn-link {
  font-weight: 800;
  letter-spacing: 0.2em;
  padding: 0 !important;
  text-decoration: none;
  color: #fff;
  display: inline-flex;
  align-items: center;
}

.btn-link i {
  font-size: 27px;
  margin-left: 12px;
}

.btn-link i,
.btn-link:hover {
  color: #01edc7;
}

.btn-lg {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  padding: 19px;
}

/* Button */
/* common */
.common-sec {
  padding: 100px 0;
  position: relative;
}

.sec-head {
  margin-bottom: 18px;
}

.sec-head .sec-title {
  display: block;
  font-weight: 900;
  font-size: 60px;
  line-height: 1.2;
  color: #f5f5f5;
  margin-bottom: 0;
}

.sec-title.title-md {
  display: block;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  color: #f5f5f5;
  margin-bottom: 0;
  text-transform: capitalize;
}

.sec-head .sec-sub-title {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #fff;
}

.cms-con {
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label,
.form-check-label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #fff;
  background-image: url(../img/right-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px;
  border-color: #fff;
}

.form-check-input:focus {
  outline: 0;
  box-shadow: none;
  border-color: #fff;
}

.form-check-input {
  background-color: transparent;
  border: 1px solid #fff;
  box-shadow: none;
}

.form-control,
.form-control:disabled,
.form-select,
.form-select:disabled {
  border-color: #fff;
  background-color: transparent;
  border-radius: 200px;
  color: #fff;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
}

.form-select {
  background-image: url(../img/arrow-down-select.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: right 18px center;
  color: rgb(255 255 255 / 50%);
}

.form-control.is-password,
.form-control.is-date {
  padding-right: 45px;
}

.form-control.is-password+i,
.form-control.is-date+i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  cursor: pointer;
}

.form-control.is-password+i,
.form-control.is-date+i {
  right: 20px;
  font-size: 18px;
}

.form-control.is-password+i,
.form-control::placeholder {
  color: rgb(255 255 255 / 50%);
}

.form-control.is-date+i {
  color: #ff6961;
}

.form-control:focus,
.form-select:focus {
  color: inherit;
  border-color: inherit;
  box-shadow: none;
  background-color: inherit;
}

.form-text {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 8px;
}

option {
  color: #000;
}

textarea {
  border-radius: 10px !important;
  resize: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* slick slider */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  color: #01edc7;
  background-color: transparent;
  font-size: 0;
  z-index: 9;
}

.slick-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.slick-arrow::before {
  font-family: "social-arts" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
}

.slick-arrow.slick-prev::before {
  content: "\e901";
}

.slick-arrow.slick-next::before {
  content: "\e902";
}

.slick-arrow.slick-prev {
  left: 0;
}

.slick-arrow.slick-next {
  right: 0;
}

.slick-dots {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
}

.slick-dots li {
  display: inline-block;
  padding: 3px;
}

.slick-dots li button {
  outline: none;
  font-size: 0;
  height: 6px;
  width: 30px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff4d;
  padding: 0;
}

.slick-dots li.slick-active button {
  background-color: #ff6961;
}

/* Leafanimation */
.left-leaf {
  animation: 4s cubic-bezier(0.87, 0.15, 0.58, 1) infinite alternate leaf_animation;
}

.right-leaf {
  animation: 4s cubic-bezier(0.18, 0, 0.25, 0.78) infinite alternate leaf_animation_two;
  animation-delay: 2s;
}

@keyframes leaf_animation {
  100% {
    transform: rotate(2deg) skew(3deg, 6deg) translate3d(-40px, 50px, 50px) scale(1.1);
  }
}

@keyframes leaf_animation_two {
  0% {
    transform: rotate(0) skew(0, 0) translate3d(0, 80px, 0) scale(1);
  }

  100% {
    transform: rotate(2.1deg) skew(2deg, 4deg) translate3d(0, 35px, 60px) scale(1.1);
  }
}

/* slick slider */
/* header */
.wrapper {
  overflow: hidden;
  padding-top: 114px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.5s ease-in-out;
  background: transparent;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.header.is-up {
  transform: translateY(-100%);
}

.header.is-sticky {
  background-color: #2f2457e0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  backdrop-filter: blur(10px);
}

.header-logo img {
  max-height: 70px;
  transition: all 0.4s ease-in-out;
}

.header.is-sticky .header-logo img {
  max-height: 40px;
}

.hero-sec.common-sec .container,
.git-card-listing-sec .container {
  max-width: 1460px;
}

.header .container {
  max-width: 1730px;
}

.header-nav {
  text-align: center;
}

.header-nav>ul>li {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.header-nav>ul>li a {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.06em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-nav>ul>li:hover>a,
.header-nav ul li .active,
.footer-menu .footer-con ul li a.active {
  color: #01edc7;
}

.header-nav>ul>li a i {
  font-size: 11px;
  margin-left: 5px;
}

.header-nav>ul>li>ul {
  position: absolute;
  top: 100%;
  left: 0;
  color: #130b41;
  background-color: #fff;
  padding: 10px 15px;
  border: 10px;
  min-width: 200px;
  max-height: 270px;
  overflow: auto;
  text-align: left;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.header-nav>ul>li>ul::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.header-nav>ul>li>ul::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.header-nav>ul>li>ul::-webkit-scrollbar-thumb {
  background-color: #01edc7;
  border-radius: 10px;
}

.header-nav>ul>li>ul li a {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
}

.header-nav>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.header .btn {
  /* font-weight: 700;
  font-size: 14px;
  line-height: 1.1;
  border-radius: 200px;
  padding: 11px 20px 9px; */
  outline: none;
  text-decoration: none;
  /* -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  -ms-border-radius: 200px;
  -o-border-radius: 200px; */
  padding: 5px 10px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  position: relative;
}

.header .btn .count {
  position: absolute;
  top: 10px;
  right: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 20px;
  width: 20px;
  border: 1px solid #2B2426;
  filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.35));
  border-radius: 50%;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  -webkit-filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.35));
  background-clip: unset !important;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #000;
  letter-spacing: 0;
}

.header-nav-toggle-btn {
  width: 28px;
  height: 28px;
  position: relative;
  padding: 0;
  color: #fff;
  background-color: transparent;
  margin-left: 18px;
  border: none;
}

.header-nav-toggle-btn span,
.header-nav-toggle-btn span:before,
.header-nav-toggle-btn span:after {
  height: 2px;
  border-radius: 50px;
  position: absolute;
  display: block;
  left: 0;
  background: currentColor;
  transition: all 0.4s ease-in-out;
}

.header-nav-toggle-btn span {
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.header-nav-toggle-btn span:before,
.header-nav-toggle-btn span:after {
  content: "";
  left: 0;
}

.header-nav-toggle-btn span:before {
  width: 80%;
  top: -7px;
}

.header-nav-toggle-btn span:after {
  width: 60%;
  bottom: -7px;
}

.nav-open .header-nav-toggle-btn span {
  background: transparent;
}

.nav-open .header-nav-toggle-btn span:before,
.nav-open .header-nav-toggle-btn span:after {
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: auto;
}

.nav-open .header-nav-toggle-btn span:before {
  transform: rotate(45deg);
}

.nav-open .header-nav-toggle-btn span:after {
  transform: rotate(-45deg);
}

/* home page start */
.hero-sec.common-sec {
  padding-top: 200px;
  margin-top: -114px;
  background-image: url(../img/hero-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40px;
}

.hero-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 90vh;
  bottom: -40vh;
  left: 20vw;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%,
      #3f328a 0%,
      rgba(56, 33, 187, 0) 100%);
  opacity: 0.8;
}

.hero-img {
  margin-right: -150px;
  margin-left: 70px;
}

.hero-con .sec-head .sec-sub-title {
  margin-bottom: 0;
}

.hero-con .sec-head .sec-title {
  font-weight: 700;
  font-size: 110px;
  margin-top: -15px;
}

.paint-n-sip {
  background-image: url(../img/paint-n-sip-texture.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  font-family: "Just Another Hand", sans-serif;
  font-size: 51px;
  line-height: 1;
  padding: 20px 45px;
  text-align: right;
  margin-top: -15px;
  margin-right: -10px;
}

.hero-con .cms-con {
  max-width: 510px;
}

.hero-sec-waves {
  margin-top: -100px;
  opacity: 0.6;
}

.explore-art-sec.common-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 90vh;
  top: -30vh;
  right: 0;
  background: radial-gradient(50% 50% at 50% 50%,
      #3f328a 0%,
      rgba(56, 33, 187, 0) 100%);
  z-index: -1;
  opacity: 0.8;
}

.explore-art-sec {
  position: relative;
}

.explore-art-sec::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(../img/explore-art.svg);
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  height: 100%;
  background-size: contain;
  opacity: 0.4;
}

.explore-art-sec .sec-head,
.experiences-sec .sec-head {
  margin-bottom: 60px;
}

.explore-art-sec .sec-head .sec-title {
  font-weight: 900;
  font-size: 100px;
  line-height: 1.22;
  background-image: url(../img/explore-text-bg.jpg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.explore-art-sec .sec-head .sec-title::after {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background-image: linear-gradient(278.79deg,
      #f66df9 10.14%,
      #01edc7 42.08%,
      #ff6961 87.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.7;
}

.explore-art-boxes {
  position: relative;
  min-height: 50vh;
}

.explore-art-box {
  position: absolute;
  top: 0;
  text-align: center;
  transition: all 1s cubic-bezier(0.26, 0.3, 0.2, 0.97);
}

.explore-art-box:nth-child(1) {
  left: 40.2%;
  width: 19.1%;
  z-index: 3;
}

.explore-art-box:nth-child(2) {
  transform: rotate(-8deg);
  left: 37%;
}

.explore-art-box:nth-child(3) {
  transform: rotate(8deg);
  left: 47%;
}

.explore-art-box:nth-child(4) {
  transform: rotate(-16deg);
  left: 34%;
}

.explore-art-box:nth-child(5) {
  transform: rotate(16deg);
  left: 53%;
}

.explore-art-box:nth-child(2),
.explore-art-box:nth-child(3) {
  width: 15.3%;
  top: 4%;
  z-index: 2;
}

.explore-art-box:nth-child(4),
.explore-art-box:nth-child(5) {
  top: 11%;
  width: 12.1%;
}

.show .explore-art-box:nth-child(2) {
  transform: translateX(-27.6vw) rotate(0);
}

.show .explore-art-box:nth-child(3) {
  transform: translateX(12.1vw) rotate(0);
}

.show .explore-art-box:nth-child(4) {
  transform: translateX(-42.4vw) rotate(0);
}

.show .explore-art-box:nth-child(5) {
  transform: translateX(30.1vw) rotate(0);
}

.show .explore-art-box:nth-child(2),
.show .explore-art-box:nth-child(3) {
  top: 20%;
}

.show .explore-art-box:nth-child(4),
.show .explore-art-box:nth-child(5) {
  top: 45%;
}

.show .explore-art-box:nth-child(2),
.show .explore-art-box:nth-child(3),
.show .explore-art-box:nth-child(4),
.show .explore-art-box:nth-child(5) {
  left: 50%;
}

.explore-art-img {
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 100%;
}

.explore-art-img img {
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.explore-art-box-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.9s cubic-bezier(0.26, 0.3, 0.2, 0.97);
}

.show .explore-art-box-title {
  opacity: 1;
}

.experiences-tabs {
  background-color: #0f093c;
  border-radius: 30px;
}

.experiences-tabs .nav-link {
  color: #fff;
  background-color: transparent;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.2;
  padding: 0 0 15px;
  position: relative;
}

.experiences-tabs .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 0;
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  max-width: 100%;
  margin-right: auto;
  transition: all 0.4s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
}

.nav-pills .nav-link.active::after {
  width: 100%;
}

.experiences-tabs .nav-item {
  margin-right: 60px;
}

.experiences-tabs .nav-item:last-child {
  margin-right: 0;
}

.experiences-tabs .nav {
  padding: 37px 40px;
}

.experiences-tabs .tab-content>.tab-pane {
  padding: 70px;
  padding-top: 0;
}

.experiences-tabs-con {
  padding-left: 65px;
}

.experiences-tabs-con .sec-head {
  margin-bottom: 12px;
}

.experiences-tabs-con .sec-head .sec-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 0;
}

.experiences-tabs-con .cms-con {
  margin-bottom: 42px;
}

.experiences-tabs-con .cms-con p {
  font-size: 16px;
  font-weight: 400;
  max-width: 530px;
}

.experiences-tabs-img img {
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.experiences-sec::after {
  content: "";
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%,
      #3f328a 0%,
      rgba(56, 33, 187, 0) 100%);
  opacity: 0.5;
  width: 37vw;
  height: 105vh;
  top: -4vw;
  left: -6vw;
  z-index: -9;
}

.experiences-sec::before {
  content: "";
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%,
      #3f328a 0%,
      rgba(56, 33, 187, 0) 100%);
  opacity: 0.2;
  bottom: 0;
  right: 0px;
  z-index: -9;
  width: 42vw;
  height: 88vh;
}

.experiences-pink-leaf {
  position: absolute;
  top: -6vw;
  left: 0;
  width: 20vw;
  z-index: -1;
}

.experiences-yellow-leaf {
  position: absolute;
  width: 12vw;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.experiences-yellow-leaf img {
  margin-left: auto;
}

.experiences-tabs-img {
  -webkit-mask-image: url(../img/experience-mask.png);
  mask-image: url(../img/experience-mask.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  padding-bottom: 100%;
  position: relative;
}

.container {
  max-width: 1205px;
  padding: 0 30px;
}

.venues-sec {
  position: relative;
}

.venues-sec.common-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 46vw;
  height: 150vh;
  top: -10vw;
  right: -17vw;
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%,
      #3f328a 0%,
      rgba(56, 33, 187, 0) 100%);
  z-index: -1;
  opacity: 0.5;
}

.venues-sec .container {
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;
}

.venues-sec .sec-head {
  margin-bottom: 36px;
}

.venues-slider {
  padding-left: 40px;
  padding-right: 40px;
}

.venues-slider .slick-arrow {
  top: 35%;
}

.venues-slide {
  padding-left: 10px;
  padding-right: 10px;
}

.venues-box-img img {
  width: 100%;
  height: 281px;
  object-fit: cover;
  border-radius: 50% 50% 0 0;
}

.venues-box-title {
  margin-top: 25px;
  color: #fff;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 800;
  display: block;
}

.venues-box-title span {
  font-weight: 400;
}

.gifts-sec::after {
  content: "";
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  opacity: 0.4;
  top: 0;
  left: -16vw;
  width: 37vw;
  height: 120vh;
  z-index: -1;
  opacity: 0.5;
}

.gifts-sec::before {
  content: "";
  position: absolute;
  opacity: 0.2;
  background: radial-gradient(50% 50% at 50% 50%,
      #f66df9 0%,
      rgba(184, 20, 188, 0) 100%);
  top: 10vw;
  right: -11vw;
  width: 41vw;
  height: 135vh;
  z-index: -1;
  opacity: 0.2;
}

.gifts-img {
  margin-left: 50px;
}

.gifts-shape-img {
  transform: translate(-85%, -50%);
  top: 50%;
}

.gifts-con .sec-head .sec-title {
  font-weight: 700;
  font-size: 34px;
}

.as-soon-on-wrap {
  border-top: 1px solid #414574;
  border-bottom: 1px solid #414574;
  padding-left: 55px;
}

.as-soon-on-wrap .sec-head {
  margin-bottom: 0;
  border-right: 1px solid #414574;
  padding: 42px;
  padding-left: 0;
}

.as-soon-on-wrap .as-soon-on-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
  color: #fff;
  margin-bottom: 0;
}

.as-soon-on-img img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  object-fit: contain;
  filter: brightness(0) invert(1);
  transition: all 0.4s ease-in-out;
}

.as-soon-on-img img:hover {
  filter: unset;
}

.as-seen-on-slider {
  padding-left: 30px;
  padding-right: 30px;
}

.video-sec::after {
  content: "";
  position: absolute;
  top: -2vw;
  right: 0;
  width: 37vw;
  height: 119vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.video-inner {
  display: block;
  position: relative;
  height: 500px;
  border-radius: 9px;
  overflow: hidden;
  max-width: 1084px;
  margin: 0 auto;
  cursor: pointer;
}

.video-inner video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-inner::before {
  content: "";
  position: absolute;
  background: linear-gradient(282.24deg,
      #01edc7 1.26%,
      #0361c5 23.99%,
      #ff6961 51.89%,
      #fa6bf9 75.66%,
      #0072dc 100.46%);
  opacity: 0.7;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  visibility: visible;
}

.video-inner.is-playing::before {
  opacity: 0;
  visibility: hidden;
}

.video-inner.is-playing .videoclick {
  opacity: 0;
  visibility: hidden;
}

.video-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.videoclick {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.video-icon {
  width: 70px;
  height: 70px;
  background-image: url(../img/svg/video-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  margin-right: 30px;
}

.video-icon.play {
  background-image: url(../img/svg/pause.svg);
}

.video-icon:before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 0.1;
  border-radius: 50%;
  z-index: -1;
  animation: callBlink 3s infinite ease-in-out;
}

.video-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.5em;
  color: #fff;
  text-transform: uppercase;
}

@keyframes callBlink {
  from {
    transform: scale(0.5);
    opacity: 0.9;
  }

  to {
    transform: scale(1.6);
    opacity: 0;
  }
}

.video-waves {
  position: absolute;
  top: -44vw;
  z-index: -1;
  left: 0;
  right: 0;
}

.collections-inr .sec-head {
  margin-bottom: 150px;
}

.collections-inr {
  overflow: hidden;
  max-width: 100vw;
}

.collections-row {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  animation: scrolling 25s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.collections-col {
  padding-left: 7px;
  padding-right: 7px;
  flex: 0 0 224px;
  max-width: 224px;
}

.collections-col .collections-img+.collections-img {
  margin-top: 14px;
}

.collections-col.s3 {
  transform: translateY(-70px);
}

.collections-col.s4 {
  transform: translateY(-20px);
}

.collections-col.s4 {
  transform: translateY(-20px);
}

.collections-col.s5 {
  transform: translateY(-90px);
}

.collections-col.s7 {
  transform: translateY(-150px);
}

.collections-img img {
  object-fit: cover;
}

.collections-img.radius-1 img {
  width: 100%;
  height: 279px;
  border-radius: 15px 15px 150px 150px;
}

.collections-img.radius-2 img {
  width: 100%;
  height: 231px;
  border-radius: 15px;
}

.collections-img.radius-3 img {
  width: 100%;
  height: 241px;
  border-radius: 150px 150px 15px 15px;
}

.collections-img.radius-4 img {
  width: 100%;
  height: 241px;
  border-radius: 15px 15px 150px 150px;
}

.testimonial-sec.common-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 126vh;
  bottom: 30px;
  left: 27vw;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  z-index: -1;
  opacity: 0.3;
}

.testimonial-sec .sec-head {
  position: relative;
  margin-bottom: 52px;
}

.testimonial-sec .sec-head .sec-title {
  font-size: 34px;
  font-weight: 700;
}

.testimonial-sec .sec-head::before {
  position: absolute;
  content: "";
  background-image: url(../img/quotemarks.svg);
  width: 130px;
  height: 91px;
  left: 27%;
  right: 0;
  top: -50px;
  bottom: 0;
  background-repeat: no-repeat;
  opacity: 0.2;
  background-size: contain;
}

.testimonial-sec .sec-head .sec-sub-title {
  text-transform: capitalize;
}

.testimonial-slider {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.testimonial-slide-box p {
  max-width: 863px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 1.4;
  margin: 0 auto;
  min-height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-name-box {
  margin-top: 33px;
}

.customer-name-box .customer-name-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 22px;
  color: #fff;
  background: #fd6b73;
  margin-right: 15px;
}

.customer-name-box span {
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
  line-height: 1.2;
}

.about-social-art-sec .container {
  max-width: 1256px;
}

.about-social-art-sec::after {
  content: "";
  position: absolute;
  top: -12vw;
  right: -20vw;
  width: 41vw;
  height: 135vh;
  z-index: -2;
  background: radial-gradient(50% 50% at 50% 50%,
      #f66df9 0%,
      rgba(184, 20, 188, 0) 100%);
  opacity: 0.2;
}

.about-social-art-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: -15vw;
  width: 37vw;
  height: 119vh;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  opacity: 0.5;
}

.about-social-art-sec .sec-title {
  font-weight: 800;
}

.about-social-art-sec .cms-con span {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  display: block;
  line-height: 1.4;
  margin-bottom: 35px;
}

.about-social-art-sec .cms-con p {
  color: #fff;
}

.about-social-art-counter {
  margin-bottom: 60px;
}

.about-social-counter-box:first-child {
  padding-left: 0;
}

.about-social-counter-box {
  border-right: 1px dashed #414574;
  min-height: 101px;
  padding: 0 28px;
}

.about-social-counter-box:last-child {
  border-right: none;
}

.about-social-counter-num span {
  background: linear-gradient(296deg,
      #f66df9 24.98%,
      #fe696f 51.3%,
      #01edc7 88.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.4;
}

.about-social-counter-box p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
  opacity: 0.5;
}

.about-social-art-img {
  padding-left: 30px;
}

.about-social-art-img img {
  width: 100%;
  object-fit: cover;
}

.about-social-art-bruch {
  position: absolute;
  top: 0;
  right: 0;
  width: 327px;
  z-index: -1;
}

.arts-save-live-sec {
  position: relative;
}

.arts-save-live-img img {
  mix-blend-mode: luminosity;
  width: 100%;
  object-fit: cover;
}

.arts-save-live-con {
  padding-left: 50px;
}

.arts-save-live-con .sec-head .sec-title {
  font-size: 34px;
}

.arts-save-live-con .cms-con p {
  color: #fff;
}

.arts-save-live-con p b {
  color: #ff6961;
}

.arts-save-live-con .cms-con {
  margin-bottom: 40px;
}

.arts-save-live-brush {
  position: absolute;
  top: 0;
  left: 0;
  width: 493px;
  height: 100%;
  z-index: -1;
}

.faq-sec::after {
  content: "";
  position: absolute;
  top: -10vw;
  left: -10vw;
  width: 41vw;
  height: 135vh;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%,
      #f66df9 0%,
      rgba(246, 109, 249, 0) 100%);
  opacity: 0.2;
}

.faq-sec::before {
  content: "";
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  opacity: 0.5;
  z-index: -1;
  top: -10vw;
  right: -140px;
  width: 41vw;
  height: 41vw;
}

.faq-sec .sec-head .sec-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
  text-align: center;
}

.faq-accordion {
  max-width: 914px;
  margin: 0 auto;
}

.faq-accordion .accordion {
  background-color: transparent !important;
  border: none;
}

.faq-sec .accordion {
  background-color: transparent !important;
  border: none;
}

.faq-sec .accordion-item {
  background-color: transparent !important;
  border: none;
}

.faq-sec button.accordion-button {
  background-color: transparent !important;
  box-shadow: none;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  padding: 35px 0;
}

.faq-accordion .accordion-header {
  transform: matrix(1, 0, 0, 1, 0, 0);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 25px;
}

.faq-accordion .accordion-body {
  padding-bottom: 30px;
  padding-left: 25px;
  padding-top: 0;
  padding-right: 25px;
}

.faq-accordion .accordion-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #fff;
  max-width: 800px;
}

.accordion-button[aria-expanded="true"]::after {
  background-image: url(../img/svg/minus.svg);
}

.accordion-button::after {
  background-image: url(../img/svg/plus.svg);
}

.newsletter-sec::after {
  content: "";
  position: absolute;
  width: 45vw;
  height: 45vw;
  top: -15vw;
  left: -9vw;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  opacity: 0.4;
  z-index: -1;
}

.newsletter-sec .form-select {
  border-radius: 12px;
  color: #000;
  background-color: #fff;
  font-size: 18px;
  line-height: 1.2;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 24px;
  transition: all 0.4s ease-in-out;
}

.newsletter-sec .form-control {
  padding-right: 24px;
}

.newsletter-sec .form-control:focus,
.newsletter-sec .form-select:focus {
  color: #fff;
}

.newsletter-box {
  max-width: 1089px;
  margin: 0 auto;
  background: linear-gradient(280.83deg, #ff6961 30.19%, #fa6bf9 99.42%);
  border-radius: 10px;
  padding: 60px;
}

.newsletter-box .sec-head {
  margin-bottom: 0;
  margin-left: 40px;
}

.newsletter-box .sec-head .sec-title {
  font-weight: 800;
  font-size: 34px;
  line-height: 1.02;
  text-transform: uppercase;
}

.newsletter-box .sec-head .sec-sub-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  text-transform: uppercase;
  position: relative;
  margin-left: 40px;
}

.newsletter-form {
  border: 1px solid #fff;
  border-radius: 200px;
}

.newsletter-form .form-group .form-control {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 16px;
  border-radius: 100px 0 0 100px;
}

.newsletter-form .form-group .form-control::placeholder {
  color: #fff;
}

.newsletter-form .btn {
  border-radius: 0px 200px 200px 0px;
  padding: 25px;
  color: #ff6961;
  background-color: #fff;
  border: none;
  margin: -1px;
}

.newsletter-box .sec-sub-title::before {
  position: absolute;
  content: "";
  width: 37px;
  height: 1px;
  border: 1px solid #fff;
  top: 9px;
  left: -40px;
}

.newsletter-img {
  position: absolute;
  z-index: -1;
  right: -205px;
  top: -229px;
}

.footer-sec {
  padding-top: 70px;
}

.footer-sec .container {
  max-width: 1260px;
  padding: 0 15px;
}

.footer-logo {
  display: block;
  width: 100%;
}

.footer-logo a img {
  width: 237px;
}

.footer-social {
  margin-top: 10px;
}

.footer-payment-img img {
  height: 24px;
}

.social-media {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.social-media a {
  width: 32px;
  height: 32px;
  font-size: 1rem;
  line-height: 2;
}

.social-media a {
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  text-align: center;
  font-size: 1.125rem;
  line-height: 2.2;
  color: #fff;
  transition: all 0.4s ease-in-out;
  margin-right: 8px;
}

.social-media a i {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media .fb:hover {
  background: #3b5998;
}

.social-media .insta:hover {
  background: #c13584;
}

.social-media .twitter:hover {
  background: #1da1f2;
}

.social-media .youtube:hover {
  background: red;
}

.social-media .pinterest:hover {
  background: #e60023;
}

.social-media .linkedin:hover {
  background: #0077b5;
}

.social-media .tiktok:hover {
  background: #fb2c53;
}

.social-media .threads:hover {
  color: #000;
  background: #fff;
}

.footer-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
}

.footer-menu .footer-con ul li {
  display: block;
  position: relative;
  line-height: 20px;
  margin-top: 20px;
}

.footer-menu .footer-con ul li a {
  font-size: 15px;
  font-weight: 500;
  color: #8985a0;
  display: block;
  transition: all 0.4s ease-in-out;
  position: relative;
  padding-left: 25px;
}

.footer-menu .footer-con ul li a i {
  background: #8985a0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 4px;
}

.footer-menu .footer-con ul li.active a,
.footer-menu .footer-con ul li:hover a {
  color: #fff;
}

.footer-menu .footer-con ul li.active a i,
.footer-menu .footer-con ul li:hover a i {
  background: linear-gradient(180deg,
      #3362c0 0%,
      #fa6bf6 23.96%,
      #f8686e 64.58%,
      #0ccec1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-box .footer-contact {
  position: relative;
  color: #8985a0;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
  width: 100%;
  padding-left: 30px;
}

.footer-box .footer-contact i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  background: linear-gradient(180deg,
      #1e61ba 0%,
      #fa6bf9 29.17%,
      #f86863 55.73%,
      #01d3c6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-copyright {
  border-top: 1px solid #58567d;
  padding: 20px 0;
}

.footer-copyright .container {
  max-width: 1260px;
  padding: 0 15px;
}

.copy-right p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  color: #8985a0;
  margin-bottom: 0;
}

.copy-right p img {
  padding-left: 5px;
}

/* home page end */
/* listing-event-page-start */

.listing-event-sec::after {
  content: "";
  position: absolute;
  top: 4vw;
  left: -18vw;
  width: 37vw;
  height: 37vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.8;
}

.listing-event-sec::before {
  content: "";
  position: absolute;
  top: 25vw;
  right: -15vw;
  width: 40vw;
  height: 40vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.9;
}

.listing-event-filter-title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.6px;
  color: #fff;
  display: block;
  padding-top: 10px;
}

.listing-search-filter-wrap .form-control {
  padding-right: 50px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 285px;
}

.city-filter-listing {
  margin-top: 30px;
}

.city-filter-listing,
.listing-event-categories,
.listing-event-venue{
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.listing-event-filter label {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.listing-event-categories,
.listing-event-venue,
.listing-event-date {
  padding: 25px 0 11px;
}

.listing-event-categories .form-label,
.listing-event-venue .form-label,
.listing-event-date .form-label {
  margin-bottom: 15px;
}

.listing-event-categories .form-group,
.listing-event-venue .form-group,
.listing-event-date .form-group {
  margin-bottom: 0;
}

.listing-event-filter .form-check label {
  font-size: 14px;
  font-weight: 500;
}

.listing-event-item-wrap {
  padding-left: 35px;
}

.listing-search-filter-wrap i {
  font-size: 12px;
  right: 20px;
  transform: translateY(-50%);
  color: rgb(255 255 255 / 50%);
}

.listing-search-filter-wrap i.with-value {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.listing-event-count p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.listing-event-sortlist {
  margin-left: 20px;
}

.listing-event-sortlist .form-label {
  margin-bottom: 0;
  margin-right: 5px;
}

.listing-event-sortlist .form-select {
  width: auto;
  display: inline-block;
  padding: 6px 12px 6px 6px;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-position: right;
}

.event-detail-location span i {
  color: #ff6961;
}

.listing-event-item-cate .event-detail-location {
  display: flex;
  align-items: center;
}

.event-detail-location+.event-detail-location {
  margin-top: 8px;
}

.listing-event-item-cate .event-detail-location:not(.text-white) i {
  color: #01edc7;
}

.listing-event-item-cate .event-detail-location p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.listing-item-box-main {
  margin-bottom: 28px;
}

.listing-event-item-cate {
  margin-top: 30px;
}

.listing-event-item-cate .event-item-title {
  margin-bottom: 8px;
  min-height: inherit;
  -webkit-line-clamp: 1;
}



.listing-filter-side-cat {
  margin-left: 15px;
  padding: 10px 16px;
  color: rgb(255 255 255 / 50%) !important;
  border: 1px solid #fff !important;
  font-size: 13px;
}

.FilterGroup .listing-filter-side-cat {
  border: 1.5px solid #FF6961 !important;
  background: rgba(251, 107, 172, 0.17) !important;
  font-size: 16px;
  color: #fff !important;
}

.listing-filter-side-cat i {
  font-size: 15px;
  padding-right: 10px;
  line-height: 1.2;
  color: #fff;
}

.listing-event-filter {
  position: fixed;
  width: 350px;
  left: 0;
  top: 0;
  background: #130b41;
  transition: all 0.6s ease-in-out;
  margin: 0;
  z-index: 999999;
  height: 100vh;
  padding: 0 0 50px 20px;
  transform: translateX(-350px);
}

.listing-event-filter .listing-filter-inner {
  height: calc(100vh - 208px);
  overflow: auto;
  padding-right: 20px;
}

.listing-event-filter .filterLogo {
  padding: 20px 0;
}
.listing-event-filter .filterLogo img {
  max-height: 50px;
}
.listing-filter-apply-btn {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  background: linear-gradient(90deg,#ff6961 0,#f66df9 50%,#ff6961);
    background-size: auto;
  background-size: 200% 200%;
  border: none;
  color: #fff;
  border-radius: 50px;
}
.listing-filter-apply-btn:hover{
  background-position: 100%;
  color: #fff;
}

.filter-back-drop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: block;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

body.filter-open .filter-back-drop {
  opacity: 0.7;
  visibility: visible;
}

body.filter-open {
  overflow: hidden;
}




body.filter-open .listing-event-filter {
  transform: translateX(0);
}

.listing-search-filter-wrap .form-control {
  width: 260px;
}

.listing-filter-close {
 position: absolute;
  border: none;
  top: 30px;
  right: 20px;
  background: transparent;
  opacity: 0.6;
}
.listing-filter-close:hover{opacity: 1;}

.listing-event-filter .listing-filter-close span::after,
.listing-event-filter .listing-filter-close span::before {
  content: "";
  width: 2px;
  height: 28px;
  position: relative;
  padding: 0;
  color: #fff;
  background-color: #fff;
  border: none;
  display: block;
}

.listing-event-filter .listing-filter-close span::before {
  transform: rotate(45deg);
}

.listing-event-filter .listing-filter-close span::after {
  transform: rotate(-45deg);
  top: -27px;
}



.event-price {
  font-size: 16px;
  font-weight: 600;
  color: #ff6961;
  line-height: 1.2;
  display: block;
  margin-bottom: 8px;
  width: 100%;
}

.loading-img {
  margin-top: 75px;
  margin-bottom: 80px;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loading-img img {
  margin: 0 auto;
}

.no-events-found img {
  max-width: 280px;
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
}

/* listing-event-page-end */
/* eventdetail-page-start */
.event-detail-sec .container {
  max-width: 1460px;
  margin: 0 auto;
  width: 100%;
}

.event-detail-slider {
  margin-bottom: 30px;
}

.event-detail-slider img {
  border-radius: 15px;
}

.event-detail-slide img {
  width: 100%;
  height: 534px;
  object-fit: cover;
}

.pottery-con .sec-head {
  margin-bottom: 8px;
}

.pottery-con .sec-head .sec-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
}

.event-detail-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 40vw;
  height: 90vh;
  bottom: -40vh;
  left: -32vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -1px;
}

.event-detail-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 145vh;
  bottom: -40vh;
  right: -30vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: 50px;
}

.event-detail-location i {
  margin-right: 6px;
}

.event-item-desc {
  margin-top: 15px;
}

.event-detail-location span {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 5px;
}

.event-detail-location p {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  display: flex;
}

.event-detail-location p i {
  color: #01edc7;
}

.event-detail-location:not(.text-white) p {
  color: rgb(255 255 255 / 50%);
}

.event-detail i {
  margin-right: 5px;
  font-size: 18px;
}

.event-detail-time {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.event-detail-time .icon-watch {
  color: #ff6961;
  margin-right: 6px;
}

.event-detail-time p {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
}

.event-detail .icon-watch {
  color: #ff6961;
}

.event-detail .icon-venue {
  color: #01edc7;
}

.pottery-price .pottery-price-con {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.33;
  color: #ff6961;
  text-align: start;
}

.pottery-detail-icon i {
  color: #ff6961;
}

.pottery-detail-con .address {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
  color: #ffffff;
  opacity: 0.5;
}

.pottery-detail-con .date-time {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.23;
  color: #ffffff;
}

.event-share .fb:hover {
  color: #4267b2;
}

.event-share .insta:hover {
  color: #e4405f;
}

.event-share .twitter:hover {
  color: #1da1f2;
}

.event-share .youtube:hover {
  color: #ff0000;
}

.event-share .pinterest:hover {
  color: #e60023;
}

.event-share .linkedin:hover {
  color: #0077b5;
}

.event-share .tiktok:hover {
  color: #fb2c53;
}

.event-share .icon-btn {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.event-share .icon-btn {
  color: #fff;
  padding: 5px 0;
  transition: all 0.4s;
}

.event-share .icon-btn i {
  margin-right: 5px;
}

.event-share .icon-btn:hover {
  color: #01edc7;
}

.event-share .whatsapp:hover {
  color: #25d366;
}

.event-share .icon-btn span {
  display: none;
  font-family: "Montserrat", sans-serif;
}

.event-about-sec .container {
  max-width: 1460px;
  margin: 0 auto;
}

.event-share-links a span {
  display: none;
}

.event-close-popup {
  display: none;
}

.venue-website-link i {
  margin-right: 10px;
}

.venue-byo-non-byo {
  margin-bottom: 0px;
  transition: all 0.4s;
  color: #ff6a6e;
  font-size: 20px;
  font-weight: 500;
}

.venue-byo-non-byo i {
  margin-right: 5px;
}

.about-con .sec-head .sec-title,
.verified-review .sec-head .sec-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  position: relative;
}

.about-con .sec-head .sec-title,
.whats-included-con .sec-head .sec-title,
.category-details-sec .sec-head.text-2 .sec-title,
.verified-review .sec-head .sec-title {
  background: linear-gradient(272deg, #ff6961, #f66df9 80%, #ff6961);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.whats-included-con .sec-head .sec-title::after,
.about-con .sec-head .sec-title::after,
.category-details-sec .sec-head.text-2 .sec-title::after,
.verified-review .sec-head .sec-title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -5px;
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 35px;
  height: 2px;
}

.social-con {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #ff6961;
  margin-right: 8px;
}

.about-con .cms-con {
  max-width: 888px;
  margin-top: 10px;
}

.about-con .cms-con h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.cms-con li {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 20px;
  position: relative;
  background-image: url(../img/right-sign-2.svg);
  background-repeat: no-repeat;
  background-position: 0 4px;
  padding-left: 25px;
}

.about-con .cms-con span {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 15px;
  display: block;
  margin-bottom: 10px;
}

.event-detail-included {
  margin-top: 40px;
}

.event-detail-included .included-title {
  font-weight: 600;
  margin-bottom: 20px;
}

.event-detail-included ul li {
  display: inline-block;
  width: 45%;
  margin-bottom: 10px;
  margin-left: 38px;
  position: relative;
  font-weight: 500;
}

.event-detail-included ul li::before {
  position: absolute;
  content: "";
  left: -40px;
  top: 4px;
  width: 16px;
  height: 16px;
  background-image: url(../img/right-sign-2.svg);
}

.book-event {
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.book-event~.get-gift-btn {
  margin-top: 42px;
}

.book-event~.get-gift-btn .btn {
  width: 86.3%;
  padding: 16px 19px;
  font-size: 14px;
}

.book-event-disable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 75%);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 19px;
  font-weight: 500;
  border-radius: inherit;
}

.book-event-disable-overlay img {
  width: 3vw;
  margin-bottom: 15px;
}

.book-event .sec-head .sec-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.recent-events .sec-head .sec-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 2;
}

.event-item-img {
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  padding-bottom: 100%;
}

.event-item-img img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
}

.event-item-box {
  color: #fff;
}

.event-item-box:hover .event-item-img img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.event-item-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 11px;
  min-height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cms-con ul.two {
  display: flex;
  flex-wrap: wrap;
}

.cms-con ul.two li {
  width: 50%;
  margin-bottom: 15px;
}

.event-detail-art-bruch-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 19vw;
  z-index: -1;
}

.event-detail-art-bruch-left img,
.event-detail-art-bruch-right img {
  object-fit: cover;
  object-position: center;
}

.event-detail-art-bruch-right {
  position: absolute;
  top: -9vw;
  right: 0;
  width: 19vw;
  z-index: -2;
}

.event-detail-art-bruch-right img {
  margin-left: auto;
}

.listing-event-clear {
  background-color: transparent;
  text-decoration: underline;
  color: #fff;
  border: 0;
  font-size: 14px;
  line-height: 1;
  transition: all 0.3s;
}

.listing-event-clear:hover {
  color: #01edc7;
}

.listing-item-box-wrapper i {
  font-size: 13px;
}

/* eventdetail-page-end*/
/* checkout-page-star*/
.checkout-sec .container {
  max-width: 1460px;
  margin: 0 auto;
}

.checkout-sec {
  position: relative;
}

.checkout-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 145vh;
  bottom: -40vh;
  left: 15vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -114px;
  opacity: 0.5;
}

.checkout-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 90vh;
  bottom: -40vh;
  right: -29vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -9;
  top: -114px;
  opacity: 0.5;
}

.checkout-sec .sec-head {
  margin-bottom: 55px;
}

.checkout-form+.checkout-form .sec-head {
  margin-bottom: 30px;
}

.checkout-sec .sec-head .sec-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  position: relative;
  margin-bottom: 0;
}

.checkout-sec .sec-head .sec-title::after {
  top: 30px;
}

.event-item-title {
  min-height: auto;
}

.checkout-payment-form .form-control {
  padding: 15px 20px;
}

.checkout-payment-form {
  display: flex;
  border: 1px solid #fff;
  border-radius: 200px;
  overflow: hidden;
}

.checkout-payment-form .form-group {
  flex: 1 1 auto;
}

.checkout-payment-form .form-control {
  border: none;
}

.btn-dark {
  background: #100844;
  color: #fff;
  border-radius: 0px 200px 200px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 14px 37px;
  border: 0;
}

.checkout-payment-form .btn:hover {
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
}

.checkout-terms {
  margin-top: 30px;
}

.checkout-terms span,
.checkout-terms p {
  font-weight: 400;
  font-size: 12px;
  line-height: 2;
}

.checkout-terms span a,
.checkout-terms p a {
  color: #ff6961;
}

.checkout-sec .form-details {
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 30px;
}

.checkout-form .form-details ul li {
  margin-bottom: 7px;
  font-weight: 500;
}

.checkout-form .form-details ul li span {
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.checkout-form .form-details ul li:last-child {
  margin-bottom: 0;
}

.checkout-form-col {
  max-width: 415px;
}

.chechout-total {
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 20px 37px;
  margin-top: 30px;
}

.remove-cart i {
  margin-right: 10px;
}

.chechout-total span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
}

.chechout-total .price-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 3px;
}

.chechout-total .price-title span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
  padding-left: 5px;
}

.remove-cart {
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  display: inline-flex;
  align-items: center;
}

.checkout-payment .nav-tabs {
  margin-bottom: 30px;
}

.card-form .btn-lg {
  margin-top: 20px;
}

.checkout-payment .nav-tabs .nav-item img {
  height: 23px;
}

.checkout-payment .nav-tabs .nav-item {
  background-color: transparent;
  border: none;
  padding: 10px;
}

.checkout-payment .nav-tabs .nav-item.active {
  border-bottom: 2px solid #01edc7;
}

.checkout-form {
  max-width: 415px;
  margin: 0 auto;
}

.checkout-form+.checkout-form {
  margin-top: 40px;
}

.checkout-item {
  padding-right: 50px;
}

.checkout-payment {
  padding-left: 50px;
}

.checkout-sec .experiences-pink-leaf {
  top: 6vw;
  width: 10vw;
  z-index: -1;
}

.checkout-sec .experiences-yellow-leaf {
  width: 9vw;
  bottom: 13vw;
  z-index: -1;
}

.checkout-terms a:hover {
  color: #01edc7;
}

/* checkout-page-end*/
/* giftcard-details-page-start */
.gift-card-sec::after {
  content: "";
  position: absolute;
  width: 37vw;
  height: 120vh;
  top: -10vw;
  left: -12vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
}

.gift-card-sec::before {
  content: "";
  position: absolute;
  width: 37vw;
  height: 120vh;
  top: -10vw;
  right: 0;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
}

.gift-card-recipient,
.gift-card-details {
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  width: 100%;
  padding: 30px;
}

.gift-card-form .sec-head {
  margin-top: 20px;
  margin-bottom: 32px;
}

.gift-card-form .sec-head .sec-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 0;
}

.gift-card-form .sec-head::after {
  position: absolute;
  content: "";
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 35px;
  height: 2px;
  left: 0;
  top: 32px;
}

.gift-card-purchase {
  margin: 10px 0;
}

.gift-card-purchase-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 8px;
}

.gift-card-purchase p {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  color: #fff;
  max-width: 550px;
}

.gift-card-purchase .calendar-icon {
  top: 13px;
  display: flex;
  right: 15px;
  color: #ff6961;
  font-size: 18px;
}

.gift-card-purchase textarea.form-control:focus {
  border-radius: 18px;
}

.amount-size label {
  border: 1px solid #fff;
  border-radius: 200px;
  padding: 12px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.amount-size label:hover {
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
  border-color: transparent;
  color: #fff;
}

.amount-size input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.amount-size input:checked+label {
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
  border-color: transparent;
  color: #fff;
}

.amount-size .form-group {
  padding-right: 10px;
  position: relative;
}

.gift-card-purchase .form-select {
  margin: 0;
  color: #fff;
}

.gift-total-amount {
  margin-top: 45px;
}

.gift-total-amount .total-price {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
}

.gift-total-amount .total-price span {
  font-size: 16px;
  font-weight: 400;
}

.gift-total-amount .btn-lg {
  max-width: 263px;
}

.gift-card-sec .gift-waves {
  z-index: -1;
  left: 0;
  right: 0;
}

.gift-cards-col {
  padding-left: 30px;
}

.gift-card-details {
  padding: 30px;
}

.gift-card-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.gift-terms-conditions a {
  color: #ff6961;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}

.gift-terms-conditions a:hover {
  color: #01edc7;
}

.recipient-details {
  margin-top: 50px;
  padding: 0 30px;
  overflow-wrap: break-word;
}

.recipient-details span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  padding-bottom: 10px;
}

.recipient-details .recipient-name {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
}

.recipient-details p {
  font-size: 14px;
  font-weight: 400;
  padding-top: 25px;
  padding-bottom: 20px;
}

.gift-best-wishes {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  line-height: 1.8;
  padding: 0 30px;
}

.gift-best-wishes span {
  font-size: 14px;
  font-weight: 500;
}

/* giftcard-details-page-end */
/*contact-us-page-start */
.contact-sec {
  position: relative;
}

.contact-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 90vh;
  bottom: -40vh;
  left: -32vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -114px;
  opacity: 0.5;
}

.contact-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 90vh;
  bottom: -40vh;
  right: -29vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -9;
  top: -114px;
  opacity: 0.5;
}

.container.is-full {
  max-width: 1451px;
}

.contact-info+.contact-info {
  margin-top: 40px;
  margin-bottom: 40px;
}

.contact-icon {
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  width: 48px;
  height: 48px;
  font-size: 21px;
  min-width: 48px;
}

.contact-detail-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: #ff6961;
  display: block;
  margin-bottom: 7px;
}

.contact-detail-sub-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.1;
}

.contact-con {
  margin-left: 15px;
}

.social-icon a {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
  border: 1px solid #ffffff;
  border-radius: 100%;
  width: 31px;
  height: 31px;
}

.social-icon a img {
  width: 19px;
  height: 19px;
  margin: auto;
  margin-top: 5px;
}

.contact-form {
  background-color: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 50px 60px;
  margin-left: 50px;
}

.contact-inr {
  max-width: 90%;
}

.contact-sec .sec-head {
  margin-bottom: 45px;
}

.contact-form .sec-head .sec-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 1.2;
  color: #ffffff;
  position: relative;
}

.contact-form .sec-head .sec-title::after,
.checkout-sec .sec-head .sec-title::after,
.booking-con .sec-head .sec-title::after {
  position: absolute;
  content: "";
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 35px;
  height: 2px;
  left: 0;
  bottom: -10px;
}

.event-share a {
  margin-right: 8px;
}

.contact-form .btn {
  margin-top: 30px;
}

.flag-contact {
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
}

.form-control.is-phone {
  padding-left: 70px;
}

.flag-contact::after {
  position: absolute;
  content: " ";
  top: -6px;
  right: -13px;
  border-right: 1px solid #d9d9d9;
  width: 1px;
  height: 32px;
  opacity: 0.2;
}

.card-form label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.contact-waves {
  position: absolute;
  left: 0;
  right: 0;
  top: -2vw;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transform: rotate(-8deg);
}

.contact-waves img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contact-pink-leaf {
  position: absolute;
  left: 0;
  top: 6vw;
  width: 10vw;
  z-index: -1;
}

.contact-yellow-leaf {
  position: absolute;
  right: 0;
  top: 25vw;
  width: 6vw;
  z-index: -1;
}

/*contact-page-us-end*/
/* login-pop-start-start */
.login-popup,
.login-popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.login-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease-in;
}

.login-popup-overlay {
  background-color: #000000;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: opacity 0.3s ease-in;
}

.login-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.login-popup-overlay.active {
  opacity: 0.6;
  visibility: visible;
}

.login-popup-inr {
  position: relative;
  background-color: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  max-width: 480px;
  padding: 45px 30px;
}

.login-popup-inr .sec-head .sec-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: #fff;
}

.login-popup-inr .sec-head::after {
  position: absolute;
  content: "";
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 35px;
  height: 2px;
  left: 0;
  top: 32px;
}

.login-form-inner {
  margin-top: 30px;
}

.forgot-password {
  font-size: 14px;
}

.login-form-inner .form-check {
  padding-bottom: 0;
}

.login-form-inner button {
  margin-bottom: 25px;
}

.popup-close {
  position: absolute;
  top: 23px;
  right: 23px;
  font-size: 20px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  border: 0;
  color: #fff;
}

.popup-close:hover {
  color: #01edc7;
}

/* login-pop-end */
/* Gift Card Listing page */
.gift-card-detail-img {
  padding: 6px;
}

.gift-card-detail-img img,
.gift-card-checkout-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 24px;
}

.gift-detail-waves {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: -1;
  right: 0;
  opacity: 0.5;
}

.gift-detail-waves img {
  width: 100%;
}

.git-card-listing-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 37vw;
  height: 125vh;
  bottom: -40vh;
  left: 0;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -114px;
}

.git-card-listing-sec::after {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 136vh;
  bottom: -40vh;
  right: -29vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -114px;
  opacity: 0.8;
}

.gift-detail-bg {
  position: absolute;
  top: -120px;
  left: 0;
  z-index: -1;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.gift-detail-bg img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* Gift-Card-Listing-page-end*/
/* venue-listing-page-star */

.victoria-sec .container,
.tasmania-sec .container {
  width: 77%;
  max-width: 1460px;
  margin: 0 auto;
}

.qeesland-sec .container {
  max-width: 1460px;
}

.qeesland-sec,
.tasmania-sec,
.victoria-sec {
  padding-bottom: 50px;
}

.qeesland-con .sec-head .sec-title,
.victoria-con .sec-head .sec-title,
.tasmania-sec .sec-head .sec-title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
}

.qeesland-sec::before {
  content: "";
  position: absolute;
  top: -4vw;
  left: -20vw;
  width: 50vw;
  height: 50vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
}

.qeesland-pink-leaf {
  position: absolute;
  top: 7vw;
  left: 0;
  width: 8vw;
  height: 255px;
  z-index: -1;
}

.qeesland-yellow-leaf {
  position: absolute;
  right: 0;
  bottom: 10vw;
  width: 8vw;
}

.qeesland-yellow-leaf,
.qeesland-pink-leaf {
  object-fit: cover;
  object-position: center;
}

.tasmania-yellow-leaf {
  position: absolute;
  top: -65px;
  right: -20px;
  width: 164px;
  height: 235px;
  z-index: -1;
}

.victoria-sec::after {
  content: "";
  position: absolute;
  top: -20vw;
  right: -20vw;
  width: 44vw;
  height: 159vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.venue-curve-img {
  position: absolute;
  bottom: -43px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.venue-curve-img img {
  width: 100%;
}

.venues-listing-item-box {
  margin-bottom: 30px;
  display: block;
}

.venues-listing-item-box .venues-listing-item-img {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
}

.venues-listing-item-box .venues-listing-item-img img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s ease-in-out;
}

a.venues-listing-item-box:hover .venues-listing-item-img img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.venues-listing-item-desc {
  padding-top: 15px;
}

.venues-listing-item-desc .venues-listing-item-title {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #ffffff;
  transition: all 0.6s ease-in-out;
}

.venues-listing-item-desc .venues-listing-item-title,
.venues-listing-item-desc p,
.venues-box-title span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

a.venues-listing-item-box:hover .venues-listing-item-title {
  color: #01edc7;
}

.venues-listing-item-desc p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 0;
  color: #ffffff;
}

.venues-listing-item-box:hover p {
  color: #ffffff;
}

/* venue-listing-page-end */
/* venue-detail-page-start */
.venue-detail-sec {
  padding-bottom: 70px;
}

.venue-detail-sec::before {
  content: "";
  position: absolute;
  top: 2vw;
  left: -21vw;
  width: 46vw;
  height: 46vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
}

.venue-detail-sec::after {
  content: "";
  position: absolute;
  top: 22vw;
  right: -24vw;
  width: 45vw;
  height: 45vw;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
}

.venue-detail-sec .container {
  max-width: 1460px;
  margin: 0 auto;
}

.venue-detail-banner img {
  border-radius: 15px;
  margin-bottom: 30px;
  margin-top: 40px;
  height: 541px;
  width: 100%;
  object-fit: cover;
}

.venue-detail-banner-con .sec-head {
  margin-bottom: 8px;
}

.venue-detail-banner-con .sec-head .sec-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
}

.venue-detail-con .venue-detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  color: rgb(255 255 255 / 0.7);
}

.venue-detail-con .venue-detail i {
  margin-right: 5px;
  font-size: 12px;
}

.venue-detail .icon-location {
  color: #01edc7;
}

.book-now-btn button {
  max-width: 306px;
}

.detail-curve-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.detail-curve-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.venue-detail-about-section {
  padding-bottom: 40px;
  position: relative;
}

.venue-detail-about-section::before {
  content: "";
  position: absolute;
  left: -70px;
  top: 26%;
  background-image: url(../img/pink-leaf.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 246px;
  height: 159px;
  z-index: -1;
  animation: 4s cubic-bezier(0.87, 0.15, 0.58, 1) infinite alternate leaf_animation;
}

.venue-detail-about-section .container {
  max-width: 1460px;
  margin: 0 auto;
}

.venue-detail-about-section .about-con .cms-con p {
  margin-bottom: 20px;
}

.venue-detail-about-section .about-con .cms-con p:last-child {
  margin-bottom: 0;
}

.venue-detail-about-section .about-con .cms-con h6 {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 5px;
  margin-bottom: 25px;
  color: #ffffff;
  opacity: 0.8;
}

.venue-location {
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 25px 30px;
}

.venue-location-map {
  height: auto;
  aspect-ratio: 16/8.5;
}

.venue-location .sec-head {
  margin-bottom: 13px;
}

.venue-location .sec-head .sec-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #ffff;
}

.venue-location h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 7px;
}

.venue-location span {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.23;
  opacity: 0.5;
}

/* offer_sec */
.offer-sec {
  padding-bottom: 70px;
}

.offer-sec .container {
  max-width: 1460px;
}

.offer-sec .sec-head {
  margin-bottom: 35px;
}

.offer-sec .offer-code {
  background: #1b1155;
  border: 1px dashed #f66df9;
  border-radius: 10px;
  padding: 20px 21px;
}

.offer-sec .offer-code .offer-code-con h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.1;
  margin-bottom: 5px;
  color: #ffffff;
  opacity: 1;
}

.offer-sec .offer-code .offer-code-con span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 0;
  color: #ffffff;
}

.offer-code-con {
  padding-left: 12px;
}

.popular-drink-food-sec .container {
  max-width: 1460px;
  position: relative;
}

.popular-drink-food-sec::after {
  content: "";
  position: absolute;
  right: -17px;
  bottom: 20px;
  background-image: url(../img/yellow-leaf.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 164px;
  height: 235px;
  z-index: -1;
  animation: 4s cubic-bezier(0.18, 0, 0.25, 0.78) infinite alternate leaf_animation_two;
  animation-delay: 2s;
}

.popular-drink-food-sec .sec-head {
  margin-bottom: 35px;
}

.popular-drink-food-sec .popular-item-row {
  margin-left: -28px;
  margin-right: -28px;
}

.popular-item-row .popular-item-col {
  width: 14.2%;
  margin-bottom: 30px;
}

.popular-item-col .popular-item-box {
  margin-right: 20px;
  margin-left: 20px;
  display: block;
}

.popular-item-box .popular-item-img {
  border-radius: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  width: 149px;
  height: 149px;
  margin: 0 auto;
}

.popular-item-box .popular-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.popular-item-box .popular-item-desc .popular-item-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}

.venue-detail-contact-sec .container {
  max-width: 1460px;
  margin: 0 auto;
}

.venue-detail-contact-sec .contact-detail-item {
  margin-bottom: 30px;
  margin-left: 45px;
}

.contact-detail-item .contact-detail-item-desc h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: #ff6961;
  margin-bottom: 10px;
}

.contact-detail-item .contact-detail-item-desc span {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #ffffff;
}

.contact-detail-item .contact-detail-item-desc a {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #ffffff;
}

.contact-detail-item .contact-detail-item-desc a:hover {
  color: #01edc7;
}

.private-event {
  background-color: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 50px;
}

.border-gradient {
  position: relative;
}

.border-gradient::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -3px;
  right: -3px;
  top: -3px;
  background: linear-gradient(45deg,
      #ff6961,
      #f66df9,
      #ffd473,
      #01edc7,
      #0365dc,
      #ff5d4f);
  background-size: 400%;
  z-index: -1;
  animation: glower 20s linear infinite;
  border-radius: inherit;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.private-event .sec-head {
  margin-bottom: 40px;
}

.private-event .sec-head .sec-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: #ffffff;
  position: relative;
}

.private-event .sec-head .sec-title::after {
  position: absolute;
  content: "";
  left: 0;
  top: 34px;
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 35px;
  height: 2px;
}

.private-event .form-label {
  font-size: 16px;
}

.private-event .btn {
  max-width: 544px;
  margin-top: 20px;
}

.private-events-pink-leaf {
  position: absolute;
  top: 0;
  left: 0;
  width: 10vw;
}

.private-events-yellow-leaf {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 8vw;
}

/* venue-detail-page-end */
/*team-building-page-start*/
.team-building-page .container {
  max-width: 1460px;
  width: 77%;
}

.why-team-build-sec {
  padding-top: 130px;
  position: relative;
}

.why-team-build-sec .container {
  max-width: 1460px;
  margin: 0 auto;
  width: 100%;
}

.why-team-build-sec::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: -20vw;
  width: 45vw;
  height: 143vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.why-team-build-sec::after {
  content: "";
  position: absolute;
  bottom: -21vw;
  right: -8vw;
  width: 44vw;
  height: 140vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.why-team-build-sec .sec-head {
  margin-bottom: 0;
}

.why-team-build-sec .sec-head .sec-title {
  margin-bottom: 33px;
}

.why-team-build-sec .cms-con {
  width: 100%;
  max-width: 81%;
  margin-bottom: 0;
}

.why-team-build-sec .cms-con p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
}

.why-team-bulid-curve-img {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: -1;
}

.why-team-bulid-curve-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.4;
}

.why-team-build-img-con {
  padding-top: 180px;
}

.why-team-bulid-item-col:nth-child(odd) {
  margin-top: -80px;
}

.why-team-bulid-item-box .why-team-bulid-item-img {
  border-radius: 20px;
  overflow: hidden;
}

.why-team-bulid-item-box .why-team-bulid-item-img img {
  width: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.why-team-bulid-item-box:hover .why-team-bulid-item-img img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.benifit-team-build-sec {
  padding-top: 148px;
  position: relative;
}

.benifit-team-build-sec .container {
  max-width: 1460px;
  margin: 0 auto;
}

.benifit-team-build-sec::before {
  content: "";
  position: absolute;
  top: -20vw;
  left: -15vw;
  width: 40vw;
  height: 40vw;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%,
      #0361c5 0%,
      rgba(3, 97, 197, 0) 100%);
  opacity: 0.5;
  z-index: -1;
}

.benifit-team-brush {
  content: "";
  position: absolute;
  top: 0;
  right: -87px;
  width: 370px;
  height: 615px;
  z-index: -1;
}

.benifit-team-brush img {
  object-fit: contain;
}

.benifit-team-build-sec .sec-head {
  margin-bottom: 60px;
}

.benifit-team-build-sec .sec-head .sec-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
}

.benifit-team-build-con {
  width: 100%;
  max-width: 575px;
}

.benifit-team-build-item .team-build-item-img {
  background: linear-gradient(278.37deg, #f66df9 15.27%, #ff6961 83.33%);
  border-radius: 200px;
  width: 46px;
  height: 46px;
  padding: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.benifit-team-build-item .team-build-item-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.benifit-team-build-item .team-build-item-desc {
  margin-left: 20px;
  margin-bottom: 48px;
}

.benifit-team-build-item .team-build-item-desc .team-build-item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 14px;
}

.benifit-team-build-item .team-build-item-desc .team-build-item-text {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.benifit-team-build-img {
  border-radius: 500px 500px 0 0;
  overflow: hidden;
  width: 100%;
  max-width: 636px;
}

.benifit-team-build-img img {
  transition: all 0.5s ease-in-out;
  height: auto;
  width: 100%;
  object-fit: cover;
}

.benifit-team-build-img:hover>img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team-build-brush {
  position: absolute;
  top: -140px;
  left: -40px;
  width: 493px;
  height: 100%;
  z-index: -1;
}

/*team-building-page-end*/
.form-error {
  color: #fff;
  font-size: 11px;
  margin-left: 10px;
  white-space: nowrap;
}

.coupon-message {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 20px;
  color: #fff;
}

.coupon-message.success,
.discount-amount-text {
  color: #00ff00;
}

.transform-left {
  transform-origin: left top;
  transform-style: preserve-3d;
}

/* date-picker */

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  padding: 12px 20px;
  border-radius: 200px;
  border-color: #fff;
}

.react-date-picker__button {
  color: #ff6961;
  padding: 0;
  padding-left: 15px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #ff6961;
}

.react-date-picker__inputGroup__input {
  outline: none;
}

.react-date-picker__wrapper {
  align-items: center;
}

.react-calendar__tile {
  color: #000;
}

.react-calendar__navigation button {
  color: #000;
}

.react-date-picker__calendar .react-calendar {
  overflow: hidden;
  border-radius: 13px;
}

.react-calendar__tile:disabled {
  color: #000;
}

.react-calendar {
  font-family: "Montserrat", sans-serif;
}

.react-calendar__navigation {
  margin-bottom: 5px;
}

.react-calendar__tile--active,
.react-calendar__tile--active:hover,
.react-calendar__tile--hasActive,
.react-calendar__tile--now {
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
  color: #fff !important;
  border-radius: 10px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--hasActive:enabled:hover {
  background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
  color: #fff;
  border-radius: 10px;
}

.react-date-picker {
  width: 100%;
}

.react-date-picker__calendar {
  top: 120% !important;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-daterange-picker--disabled {
  background-color: #ef5166;
  color: white;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid gray;
}

.react-daterange-picker__inputGroup {
  min-width: auto;
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  color: #fff;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: transparent;
  outline: none;
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-daterange-picker__button:enabled {
  cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #ef5166;
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}

.react-daterange-picker__button svg {
  display: inherit;
  stroke: #fff;
}

.react-daterange-picker__calendar {
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-daterange-picker__calendar--closed {
  display: none;
}

.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-daterange-picker * {
  font-family: inherit;
}

.react-daterange-picker--closed {
  border-radius: 8px;
}

.react-daterange-picker--closed .react-daterange-picker__wrapper {
  border-radius: 200px;
}

.react-daterange-picker--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-daterange-picker--open .react-daterange-picker__wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-daterange-picker__wrapper {
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ededed;
}

.react-daterange-picker__inputGroup {
  min-width: auto;
  flex-grow: 1;
  display: flex;
  padding: 2px;
  align-items: baseline;
}

.react-daterange-picker__inputGroup__divider {
  padding: 1px;
}

.react-daterange-picker__inputGroup__input {
  -moz-appearance: textfield;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__calendar {
  border-radius: 8px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
}

.react-daterange-picker__calendar .react-calendar {
  border-radius: 13px;
  overflow: hidden;
}

.react-daterange-picker__clock {
  border-radius: 8px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  font-family: inherit;
  width: 220px;
  height: 220px;
  padding: 20px;
  border: 0;
  border-top-left-radius: 0;
}

.react-daterange-picker__clock .react-clock__second-hand {
  transition: transform cubic-bezier(0.68, 0, 0.27, 1.55) 0.2s;
}

.react-daterange-picker__clock .react-clock__second-hand__body:before {
  width: 5px;
  height: 5px;
}

.react-daterange-picker__clock .react-clock__second-hand__body:after {
  width: 15px;
  height: 15px;
}

.react-daterange-picker--open {
  border-radius: 8px;
  border-bottom-left-radius: 0;
}

.react-daterange-picker--open .react-daterange-picker__wrapper {
  border-radius: 200px;
}

.react-date-picker__button {
  padding: 0 6px;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-daterange-picker__button {
  border: 0;
  padding: 0px 6px;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  transition: all 0.4s;
  font-size: 16px;
  color: #9a9a9a;
}

.react-daterange-picker__wrapper {
  border-radius: unset;
  font-size: 1em;
  padding: 8px 7px;
}

.react-daterange-picker__calendar {
  min-width: 320px;
}

.react-daterange-picker__inputGroup__input::placeholder {
  color: #fff;
}

.booking-success {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background-color: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 30px;
  padding: 50px 60px;
}

.booking-success>.sec-head .sec-title {
  font-weight: 700;
  font-size: 30px;
  color: #01edc7;
}

.booking-success>.sec-head img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.booking-success-message {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 30px;
}

.booking-con .sec-head {
  margin-bottom: 30px;
}

.booking-con .sec-head .sec-title {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
}

.booking-con .table {
  color: #fff;
  margin-bottom: 30px;
}

.booking-con .table:last-child {
  margin-bottom: 0;
}

.booking-con .table th,
.booking-con .table td {
  padding-left: 0;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  background-color: transparent;
}

.booking-con .table th {
  font-weight: 600;
  vertical-align: baseline;
}

.booking-ticket {
  background-color: #312573;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 40px;
}

.booking-ticket-date {
  font-weight: 700;
  font-size: 100px;
  line-height: 1.2;
}

.booking-ticket-month {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.booking-ticket-time {
  display: block;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.booking-ticket-label {
  font-size: 14px;
  line-height: 1.2;
}

.booking-ticket-id {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
}

/* thank-you-page */

.thank-you-wrap::before {
  content: "";
  position: absolute;
  top: -6vw;
  left: 6vw;
  width: 46vw;
  height: 120vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.thank-you-wrap::after {
  content: "";
  position: absolute;
  top: -6vw;
  right: -3vw;
  width: 37vw;
  height: 120vh;
  z-index: -1;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  opacity: 0.5;
}

.thank-you-sec.common-sec {
  padding-top: 180px;
}

.thank-you-sec .thankyou-pink-leaf {
  position: absolute;
  top: 6vw;
  left: 0;
  width: 9vw;
  z-index: -1;
}

.thank-you-sec .thankyou-yellow-leaf {
  position: absolute;
  width: 8vw;
  bottom: 0;
  right: 0vw;
  z-index: -1;
}

/* notfound-page */

.notfound-pink-leaf {
  position: absolute;
  top: 6vw;
  left: 0;
  width: 9vw;
  z-index: -1;
}

.notfound-yellow-leaf {
  position: absolute;
  width: 7vw;
  bottom: 1vw;
  right: 0vw;
  z-index: -1;
}

.afterpay-checkout-btn__wrapper {
  padding: 0 !important;
  background-color: transparent !important;
  color: inherit !important;
  justify-content: center;
}

/* loader-sec */
.loader-sec {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0f2366;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0, 0.28, 0.33, 1.05);
}

.loader-sec.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.loader-img img {
  width: 7vw;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50px);
  }
}

.react-time-picker {
  width: 100%;
}

.react-clock__mark__body,
.react-clock__hand__body {
  background-color: #fff;
}

.react-clock__face {
  border-color: #fff;
  background-color: transparent;
}

.react-time-picker__clock {
  background-color: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 20px;
  display: none !important;
}

.react-clock__second-hand__body {
  background-color: #ff6961;
}

.react-time-picker__button svg {
  stroke: #ff6961;
}

.react-time-picker__inputGroup__input {
  outline: none;
  border: none;
}

/* category-details-sec */
.category-details-sec-waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0.6;
  margin-top: -100px;
  mix-blend-mode: color-dodge;
}

.category-details-con .cms-con h5 {
  font-size: 26px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important;
  color: #fff !important;
  margin-bottom: 20px;
}

.category-details-sec-waves img {
  width: 100%;
}

.category-details-sec .sec-head .sec-title {
  margin-bottom: 30px;
}

/* whats-included-sec */
.sec-head.text-2 {
  margin-bottom: 30px;
}

.sec-head.text-2 .sec-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.3;
}

.whats-included-con {
  padding-left: 70px;
}

.whats-included-con .cms-con li {
  font-size: 20px;
  font-weight: 500;
  background-image: url(../img/svg/tick-square.svg);
  background-position: center left;
  background-size: 20px;
  padding-left: 35px;
  margin-bottom: 15px;
}

.whats-included-art-bruch-right {
  position: absolute;
  top: -9vw;
  right: 0;
  width: 13vw;
  z-index: -1;
}

.whats-included-art-bruch-left {
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 13vw;
  z-index: -1;
}

.whats-included-art-bruch-left img,
.whats-included-art-bruch-right img {
  object-fit: cover;
  object-position: center;
}

.whats-included-art-bruch-right img {
  margin-left: auto;
}

.whats-included-img img {
  border-radius: 500px 500px 0 0;
  width: 636px;
  height: 705px;
  object-fit: cover;
}

.remaining-balance-amt {
  font-size: 14px;
}

.whats-included-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 45vw;
  height: 45vw;
  right: -12vw;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -19vw;
}

.category-details-sec::before {
  content: "";
  display: block;
  position: absolute;
  width: 40vw;
  height: 80vh;
  left: -32vh;
  background: radial-gradient(39.67% 39.67% at 50% 50%,
      #3f328a 0%,
      rgba(63, 50, 138, 0) 100%);
  z-index: -1;
  top: -4vh;
}

.scroll-to-top {
  position: fixed;
  bottom: 12%;
  right: 2%;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 0;
  padding: 0 !important;
  animation: 2s ease-in-out infinite alternate topBottom;
  z-index: 9;
}

@keyframes topBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.scroll-to-top span {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  vertical-align: middle;
  margin-top: 6px;
}

.footer-cms-links {
  padding: 50px 0 20px;
  margin: 0 -5px;
}

.footer-cms-links a {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  color: #8985a0;
  padding: 0 7px;
  position: relative;
  transition: all 0.3s;
}

.footer-cms-links a::after {
  position: absolute;
  content: "/";
  left: auto;
  top: 0;
  padding-left: 5px;
  color: #8985a0 !important;
}

.footer-cms-links a:last-child::after {
  display: none;
}

.footer-cms-links a:hover {
  color: #fff;
}

.listing-event-categories .listing-event-list {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
}

.listing-event-categories .listing-event-list::-webkit-scrollbar,
.listing-event-venue .listing-event-list::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.listing-event-categories .listing-event-list::-webkit-scrollbar-track,
.listing-event-venue .listing-event-list::-webkit-scrollbar-track {
  background-color: #ffffff38;
  border-radius: 10px;
}

.listing-event-categories .listing-event-list::-webkit-scrollbar-thumb,
.listing-event-venue .listing-event-list::-webkit-scrollbar-thumb {
  background-color: rgb(255 255 255 / 80%);
  border-radius: 10px;
}

.listing-event-venue .listing-event-list {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
}

.listing-item-box-wrapper {
  max-width: 20%;
  flex: 0 0 20%;
}

.bookingProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 60%);
  z-index: 999;
}

.bookingProgress span {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #f66df9);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}


.hero-video-sec {
  max-height: 950px;
  height: 100vh;
  position: relative;
  padding-top: 114px;
  margin-top: -114px;
  display: flex;
  align-items: center;
}

.hero-video-sec .container {
  max-width: 1560px;
  position: relative;
  z-index: 2;
}

.hero-video-sec .sec-head .sec-title {
  font-size: 70px;
  font-weight: 700;
  letter-spacing: -1.4px;
}

.hero-video-sec .cms-con p {
  font-size: 24px;
}

.hero-video-sec .text-holder-video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-video-sec:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(19, 11, 65, 0.6);
  z-index: 1;
}

.popular-events-sec {
  padding-top: 90px;
}

.popular-events-container {
  max-width: 1750px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.popular-events-row {
  display: flex;
  gap: 40px;
  align-items: center;
  /* overflow-x: auto; */
}

.popular-events-link {
  flex: 0 0 70px;
  max-width: 70px;
  text-align: center;
}

.popular-events-link span {
  margin-top: 12px;
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 1.83;
  text-transform: uppercase;
  display: block;
  transition: .4s all ease-in-out;
  -webkit-transition: .4s all ease-in-out;
  -moz-transition: .4s all ease-in-out;
  -ms-transition: .4s all ease-in-out;
  -o-transition: .4s all ease-in-out;
}

.popular-events-link:hover span {
  color: #01EDC7;
}

.popular-events-sec i {
  font-size: 12px;
}

.popular-events-sec .event-price {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  color: #fff;
}

.popular-events-sec .event-item-title {
  margin-bottom: 5px;
}

.popular-events-sec .listing-event-item-cate {
  margin-top: 20px;
  width: 100%;
}

.popular-events-sec .sec-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popular-events-sec .see-more-link {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.2em;
  gap: 5px;
  white-space: nowrap;
}

.popular-events-sec .see-more-link svg {
  height: 14px;
  width: 14px;
}

.categaryEventsMain {
  padding: 30px 0;
}

.categaryEventsMain .popular-events-sec {
  padding: 50px 0;
}

.header-search {
  display: inline-block;
  padding-right: 20px;
}

.header-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-search .input-group {
  max-width: 255px;
  border-radius: 200px;
  border: 1.5px solid rgba(119, 119, 119, 0.70);
}

.header-search .input-group:focus-within {
  border-color: #fff;
}

.header-search .input-group-text,
.header-search .form-control {
  margin-left: 0 !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0.17);
  color: #fff;
}

.header-search .input-group-text-close {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 14px !important;
  z-index: 5;
  cursor: pointer;
}

.header-search .form-control {
  padding-left: 5px;
  padding-right: 40px;
  border-radius: 0 200px 200px 0 !important;
  -webkit-border-radius: 0 200px 200px 0 !important;
  -moz-border-radius: 0 200px 200px 0 !important;
  -ms-border-radius: 0 200px 200px 0 !important;
  -o-border-radius: 0 200px 200px 0 !important;
}

.header-search .input-group-text {
  font-size: 22px;
  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  -ms-border-radius: 200px;
  -o-border-radius: 200px;
}

.banner-event-booking-sec {
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.22);
  background: rgba(16, 8, 68, 0.75);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 30px 35px;
  max-width: 780px;
  justify-content: space-between;
}

.book-event .banner-event-or-text {
  height: 38px;
  width: 38px;
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.banner-event-or-text {
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
  background-color: #1E0D87;
  border: 1px solid #474067;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 0;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.banner-event-booking-sec form {
  display: flex;
  gap: 10px;
  flex: 1;
}

.banner-event-booking-sec form select:nth-child(1) {
  flex: 0 0 190px;
}

.event-from .input-group {
  padding: 14px 15px !important;
  align-items: center;
  flex: 0 0 100px;
  padding: 0 15px;
  border: 1.5px solid #fff;
  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  -ms-border-radius: 200px;
  -o-border-radius: 200px;
}

.event-from .input-group .form-select {
  border: 0;
  padding: 0;
  padding-left: 8px;
  background-position: right center;
}

.event-from-row {
  display: flex;
  gap: 10px;
}

.event-from .input-group .icon-user {
  font-size: 18px;
  color: #01EDC7;
}

.event-from .input-group .icon-calendar {
  font-size: 18px;
  color: #FF6961;
}

.event-from-row .form-group.event-from {
  flex-grow: 1;
}
.event-from-row .form-group.event-from.event-from-person{
max-width: 150px;
}
/* .event-from-row i{
  font-size: 16px;
} */
.banner-event-booking-sec form .btn {
  flex: auto;
}

.banner-event-booking-sec .btn {
  font-size: 14px;
  letter-spacing: 0;
  padding: 16px 20px;
}

.btn-outline-pink {
  color: #fff;
  border: 1.5px solid #FF6961;
  background: rgba(251, 107, 172, 0.17);
}

.btn-outline-pink:hover {
  background: rgba(251, 107, 172, 1);
}

.header-search {
  position: relative;
}

.search-open {
  position: absolute;
  left: 0;
  min-width: 330px;
  top: 124%;
  background: #fff;
  /* background: #0e2062; */
  /* border: 1px solid #fff; */
  background: #100844;
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 15px;
  overflow: hidden;
}

.search-open-wrapper {
  max-height: 403px;
  overflow: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.search-event-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.search-event-item:first-child {
  border-top: 0;
}

.search-event-img {
  margin-right: 12px;
}

.search-event-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  min-width: 60px;
  border-radius: 10px;
}

.search-event-desc {
  text-align: left;
}

.search-event-title {
  /* color: #000; */
  font-size: 13px;
  margin: 0;
}

.search-event-price {
  font-size: 13px;
  /* color: #666; */
}

.header .search-open .search-event-btn {
  text-align: left;
  font-size: 14px;
  padding: 14px;
  margin: 0 auto;
  display: table;

}

.search-no-events-found img {
  width: 100%;
  max-width: 140px;
  margin: 20px auto;
}

.search-loading-img img {
  max-width: 40px;
  margin: 20px auto;
}

.book-event .sec-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.reviews {
  position: relative;
  display: flex;
  gap: 5px;
}

.reviews .star-outline {
  flex: 0 0 80px;
  position: relative;
  background-image: url(../img/svg/star.svg), url(../img/svg/star.svg), url(../img/svg/star.svg), url(../img/svg/star.svg), url(../img/svg/star.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  height: 14px;
  width: 80px;
  background-position: 0px 0, 16px 0, 32px 0, 48px 0, 64px 0;
}

.star {
  position: absolute;
  background-image: url(../img/svg/g-star.svg), url(../img/svg/g-star.svg), url(../img/svg/g-star.svg), url(../img/svg/g-star.svg), url(../img/svg/g-star.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  height: 14px;
  width: var(--review);
  overflow: hidden;
  background-position: 0px 0, 16px 0, 32px 0, 48px 0, 64px 0;
}

.review-count {
  color: #FC6B98;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
}

.event-tag {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.26px;
  margin-bottom: 30px;
}

.event-tag ul {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.event-tag ul li {
  position: relative;
}

.event-tag ul li::after {
  content: '';
  display: block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateX(-50%);
  right: -18px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.event-tag ul li:last-child::after {
  content: none;
}