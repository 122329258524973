.event-detail-grid-sec {
    padding-top: 15px !important;
}

.grid-layout {
    display: grid;
    gap: 15px;
    height: 100%;
}

.layout-7 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-item:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
}

.grid-item:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
}

.grid-item:nth-child(3) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
}

.grid-item:nth-child(4) {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 3;
}

.grid-item:nth-child(5) {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 5;
}

.grid-item:nth-child(6) {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: 3;
    grid-column-end: 7;
}

.grid-item:nth-child(7) {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: 3;
    grid-column-end: 9;
}

.event-detail-grid-sec .container,
.event-detail-layout2 .container {
    max-width: 1460px;
}

.grid-item,
.grid-slider-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.grid-item .img-box {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    padding-bottom: 135%;
}


.grid-item .img-box img,
.grid-item .img-box video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.grid-item-box {
    display: flex !important;
    flex-wrap: wrap;
}

.grid-item-box .grid-box {
    width: 50%;
    margin-bottom: 10px;
}

.grid-item-box .grid-box:nth-child(odd) {
    padding-right: 5px;
}

.grid-item-box .grid-box:nth-child(even) {
    padding-left: 5px;
}

.grid-item-box .img-box {
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.grid-item-box .img-box img,
.grid-item-box .img-box video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.grid-item .slick-dots {
    bottom: 20px;
}

.grid-layout-mob .slick-arrow.slick-prev {
    display: none !important;
}

.grid-layout-mob .slick-arrow.slick-next {
    top: 48%;
    color: #fff;
    background: linear-gradient(to right, #ff6961 0%, #f66df9 50%, #ff6961 100%);
    background-size: 200% 200%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.grid-layout-mob .slick-arrow.slick-next::before {
    content: '';
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    height: 15px;
    width: 15px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-47deg);
    -webkit-transform: rotate(-47deg);
    position: absolute;
    top: 0;
    right: 0;
    left: -6px;
    bottom: 0;
    margin: auto;
}

.layout-2 {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr 1fr;
}

.layout-2 .grid-item:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 3;
}

.layout-2 .grid-item:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-column-end: 4;
}

.layout-2 .grid-item:nth-child(1) .img-box {
    padding-bottom: 44.36%;
    height: 98%;
}

.layout-1 .img-box {
    padding-bottom: 38.6%;
}


.grid-box.grid-box-single {
    width: 100%;
    aspect-ratio: 16/9;
}


@media (max-width:1299px) {
    .layout-7 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .layout-2 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .layout-7 .grid-item:last-child {
        display: none;
    }
}


@media (max-width:991px) {

    .layout-7 .grid-item:nth-child(1),
    .layout-7 .grid-item:nth-child(2),
    .layout-7 .grid-item:nth-child(3),
    .layout-7 .grid-item:nth-child(4) {
        display: none;
    }

    .grid-item .img-box {
        padding-bottom: 110%;
    }

    .grid-item .img-box img {
        width: 100%;
    }

    .grid-item:nth-child(5) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 4;
    }

    .grid-item:nth-child(6) {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-row-end: 3;
        grid-column-end: 7;
    }

    .layout-2 {
        grid-template-columns: 2fr auto 1fr;
    }

}

@media (max-width:568px) {

    .event-detail-grid-sec {
        padding-top: 0 !important;
        padding-bottom: 40px !important;
    }

    .layout-2 .grid-item:nth-child(1) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 4;
    }

    .layout-2 .grid-item:nth-child(2) {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 4;
    }

    .grid-item .img-box,
    .grid-item .img-box img {
        border-radius: 10px;
    }

    .layout-2 .grid-item:nth-child(1) .img-box,
    .grid-item .img-box {
        padding-bottom: 80%;
        height: auto;
    }
}


.mobileVid {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 15px;
    left: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mobileVid:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid #fff;
    border-right: 0;
    position: absolute;
    content: '';
}

.mobileVid:hover {
    background: rgba(0, 0, 0, 0.7);
}

.detail-popup {
    overflow: hidden;
}

.event-mob-popup {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    width: 100%;
}

.event-mob-popup-box {
    width: 90%;
    padding: 50px 20px 40px 20px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.22);
    background: #130b41;
    position: relative;
}

.border-gradient::before {
    display: none;
}

.event-mob-popup .book-event {
    border-radius: 0;
    border: 0;
    padding: 0 0 42px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.event-mob-popup .book-event+.btn {
    margin-top: 42px;
}

.event-mob-popup .event-close-popup {
    top: 3%;
    right: 3%;
    z-index: 10;
}

.event-mob-popup .event-close-popup i {
    font-size: 24px;
}

.detail-sticky-row {
    align-items: center;
    padding: 10px 10px 10px;
    background: #2f2457;
    border-top: 1px solid rgba(255, 255, 255, 0.22);
}

.detail-sticky-col {
    width: 60%;
    padding-right: 8px;
    text-align: left;
}

.detail-sticky-col2 {
    width: 40%;
    text-align: right;
}

.detail-sticky-col .reviews-img img {
    width: 18px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 2px;
}

.detail-sticky-col .reviews-img span {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1;
}

.detail-sticky-col .pottery-price-con {
    color: #ff6a6e;
    padding-top: 5px;
    margin-bottom: 0;
}

.detail-sticky-col2 .btn {
    font-size: 13px;
    padding: 15px 18px;
}

.venue-website-link {
    padding-top: 6px;
}

.verified-review-slider {
    overflow: hidden;
    border-radius: 15px;
}

.verified-review {
    padding-bottom: 54px;
    padding-top: 18px;
}

.verified-review-list {
    padding-top: 17px;
}

.verified-review .sec-head .sec-title {
    padding-right: 15px;
}

.verified-review .verified-review-box {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 15px;
}

.verified-review .img-box {
    height: auto;
    width: 100%;
    position: relative;
    padding-bottom: 100%;
}

.verified-review .img-box img {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.verified-review-name {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    display: inline-block;
    padding-right: 19px;
    margin-bottom: 0;
    vertical-align: middle;
}

.verified-date-text {
    display: inline-block;
    color: #8985A0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
}

.verified-date-text i {
    color: #FF6961;
    display: inline-block;
    font-size: 18px;
    line-height: 1.1;
    vertical-align: middle;
    padding-right: 5px;
}

.verified-review-list .row>div:first-child+div .verified-content {
    padding-left: 27px;
}

.verified-content .reviews {
    padding-top: 10px;
    padding-bottom: 17px;
}

.verified-content .cms-con p {
    font-weight: 500;
    line-height: 1.5;
}

.verified-review .slick-dots {
    bottom: 16px;
}

.verified-btn .btn-lg {
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 19px;
}

.verified-btn .btn-lg i {
    padding-left: 15px;
    vertical-align: middle;
    font-size: 11px;
    opacity: .5;
}

.pottery-con+.pottery-price {
    margin-top: 25px !important;
    margin-bottom: 15px;
}

.event-share+.about-con {
    padding-top: 50px;
}

.recent-events .sec-head {
    max-width: 59.5%;
}

.recent-events .popular-events-link {
    flex: 1;
    max-width: inherit;
    text-align: right;
}

.recent-events .popular-events-link a {
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
}

.recent-events .popular-events-link a svg {
    height: 10px;
    width: 10px;
    margin-left: 5px;
    display: inline-block;
}

.write-review-sec .contact-pink-leaf,
.write-review-sec .contact-yellow-leaf {
    display: none;
}

.write-review-sec .container {
    max-width: 1130px;
}

.write-review-sec .contact-form {
    padding: 30px 30px 50px;
}

.write-review-sec .contact-inr {
    max-width: 100%;
}

.write-slider,
.write-img-list,
.write-img-list .slick-list,
.write-img-list .slick-track,
.write-img-list .slick-slide > div {
    height: 100%;
    width: 100%;
}

.write-review-row .img-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    padding-bottom: 100%;
    position: relative;
}

.write-review-row .img-box img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.write-review-row .rating-box {
    margin: 5px 0 20px;
}

.write-review-row .rating-box>span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.rating {
    display: flex;
    width: 100%;
    justify-content: start;
    overflow: hidden;
    flex-direction: row-reverse;
    height: 24px;
    position: relative;
    padding-left: 15px;
}

.rating-0 {
    filter: grayscale(100%);
}

.rating>input {
    display: none;
}

.rating>label {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: auto 1px;
    background-image: url("../img/svg/star.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    transition: .3s;
}

.rating>input:checked~label,
.rating>input:checked~label~label {
    background-image: url("../img/svg/g-star.svg");
}

.rating>input:not(:checked)~label:hover,
.rating>input:not(:checked)~label:hover~label {
    background-image: url("../img/svg/g-star.svg");
}

.upload-box {
    position: relative;
    width: 100%;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed #5B49C1;
    background: #1F155A;
}

.upload-content {
    text-align: center;
}

.upload-box img {
    margin: auto;
    width: 50px;
    height: 44px;
    object-fit: contain;
}

.upload-box .form-control {
    opacity: 0;
    position: absolute;
    height: 16px;
    width: 50px;
    top: 50%;
    left: 56%;
}

.upload-content .drag-text {
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 0 2px;
}

.upload-content .drag-text .browse-text {
    color: #FF6961;
    font-weight: 700;
    text-decoration-line: underline;
}

.upload-content .support-formate {
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: .5;
}

.write-review-sec .contact-waves {
    position: absolute;
    left: 0;
    right: 0;
    top: 2.7vw;
    bottom: 0;
    z-index: -1;
    width: 103%;
    height: 100%;
    transform: rotate(0deg);
    opacity: .5;
}

.upload-photos-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.upload-photos-wrapper .upload-photos {
    max-width: 60px;
    aspect-ratio: 1;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    position: relative;
}

.upload-photos img {
    object-fit: cover;
    width: 100%;
}

.upload-photos i {
    position: absolute;
    right: 3px;
    font-size: 6px;
    text-align: right;
    color: #fff;
    top: 3px;
    cursor: pointer;
    background: #FF6961;
    padding: 5px;
    border-radius: 50%;
}

@media (max-width:1199px) {
    .verified-review-list .row>div:first-child+div .verified-content {
        padding-left: 0;
    }

    .verified-btn .btn-lg {
        margin-top: 10px;
    }

    .pottery-con+.pottery-price {
        margin-bottom: 10px;
    }

    .event-share+.about-con {
        padding-top: 40px;
    }

    .recent-events .sec-head {
        max-width: 74.5%;
    }

    .write-review-sec .contact-form {
        margin-top: 0;
    }

    .write-review-sec .contact-waves {
        display: none;
    }
}

@media (max-width:991px) {
    .verified-review {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .recent-events .sec-head {
        max-width: 100%;
    }

    .write-review-row .img-box {
        padding-bottom: 75%;
    }

    .write-img-list{
        margin-top: 50px;
    }
}

@media (max-width:568px) {
    .verified-review {
        padding-bottom: 0;
    }

    .verified-review-list .row>div:first-child+div .verified-content {
        padding-top: 15px;
    }

    .verified-content .reviews {
        padding-bottom: 12px;
    }

    .pottery-con+.pottery-price {
        margin-top: 12px !important;
    }

    .event-share+.about-con {
        padding-top: 25px;
    }

    .recent-events .popular-events-link {
        display: block;
    }

    .write-review-sec .contact-form {
        padding: 30px 15px 30px;
    }
    .verified-review-slider{
        max-width: 300px;
    }
}