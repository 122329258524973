@font-face {
  font-family: "social-arts";
  src: url("fonts/social-arts.eot?n2iwi");
  src: url("fonts/social-arts.eot?n2iwi#iefix") format("embedded-opentype"),
    url("fonts/social-arts.ttf?n2iwi") format("truetype"),
    url("fonts/social-arts.woff?n2iwi") format("woff"),
    url("fonts/social-arts.svg?n2iwi#social-arts") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "social-arts" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e925";
}
.icon-user:before {
  content: "\e926";
}
.icon-venue:before {
  content: "\e924";
}
.icon-instagram-threads:before {
  content: "\e923";
}
.icon-tickets:before {
  content: "\e922";
}
.icon-tiktok:before {
  content: "\e921";
}
.icon-fast-food:before {
  content: "\e920";
}
.icon-whatsapp:before {
  content: "\e91e";
}
.icon-copy-link:before {
  content: "\e91f";
}
.icon-close-popup:before {
  content: "\e91d";
}
.icon-share:before {
  content: "\e91c";
}
.icon-world-wide-web:before {
  content: "\e91b";
}
.icon-paper-plane:before {
  content: "\e91a";
}
.icon-pause:before {
  content: "\e919";
}
.icon-pinterest:before {
  content: "\e917";
}
.icon-linkedin:before {
  content: "\e918";
}
.icon-close:before {
  content: "\e916";
}
.icon-brush:before {
  content: "\e915";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-call:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e905";
}
.icon-eye:before {
  content: "\e906";
}
.icon-eye-slash:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-location:before {
  content: "\e90b";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-play:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-remove:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e911";
}
.icon-video-icon:before {
  content: "\e912";
}
.icon-watch:before {
  content: "\e913";
}
.icon-youtube:before {
  content: "\e914";
}
